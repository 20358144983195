import React, { useEffect } from 'react';

const Law = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="wrapper">
            <img className="collections_header_bg" src="/home_header_bg.png" />
            <div className="policyContent">

                <h1>SOMBRA LAW</h1>
                <h2>
                    What is SOMBRA LAW?
                </h2>
                <p>
                    Sombra Law is a set of rules outlining the norms, rules, and responsibilities or proper practices of an individual party or an organization who is interacting with the Sombra community and its platform.
                </p>
                <h2>Sombra Law</h2>
                <p>Simple. Kind. Honest. </p>
                <p>
                    At Sombra we want things to be simple. Simple for you and simple for us. Because of that we are 100% transparent with all financial transactions and inner workings as well as with how we have structured our smart contracts to interact with the network. We also want you to be kind. Respect and goodwill are sometimes rare in the crypto world, and we aim to create and foster a safe space for users and collectors of all levels. If you don’t have anything nice to say don’t say anything. Honesty is also paramount and Sombra does not tolerate scams, copyright infringement, harassment, incitement of violence, or any other content or actions we deem harmful to the community. For any questions about our standards please refer to our Terms and Conditions, Privacy Policy, & Standards and Workflow Document.
                </p>
                <h2>For Everyone</h2>
                <ul>
                    <li>Treat all members of the community with respect</li>
                    <li>Talk to each other first when issues arise to find amicable solutions</li>
                    <li>Do not threaten or attack any community or team member </li>
                    <li>Be inclusive, non-judgmental, and polite - civil discussion should be possible without attacking each other on a personal level</li>
                    <li>Wash trading, price exploitation or manipulation will not be tolerated</li>
                    <li>Flag scams, fakes and other nefarious activities will not be tolerated and should be reported to support@sombra.app </li>
                    <li>Each user is personally responsible for their applicable taxes in their local area. NFTs are taxed as per USA and most other countries financial law. You must make sure you are following all local laws and regulations as well as paying appropriate taxes. Sombra always maintains transparent records and pay taxes in accordance with US regulations.</li>
                </ul>
                <h2>For Artists</h2>
                <ul>
                    <li>Ensure you always accredit your artworks if needed and that copyright is not in violation. Users who do not follow this rule are subject to legal action against Sombra as well as the original creators. Sombra reserves the right to remove any NFT from its platform if found in violation. </li>
                    <li>Ensure your artworks are always unique on the Sombra platform. We do not accept artwork that has been minted already on other platforms nor double minting in general.</li>
                    <li>Sombra will only market and showcase cleared works. If your work has not been verified it cannot be showcased in top creator spots.</li>
                    <li>Ensure your creations do not infringe copyright and or other intellectual materials, including audio. </li>
                    <li>All collaborative art must be submitted with all artists approval and signatures</li>
                    <li>If found in violation of these rules Sombra reserves the right to freeze the artists account and pay damages from sales accordingly. </li>
                    <li>You must follow and adhere to the Sombra Network export and file specifications. If you do not follow these, we cannot help you.</li>
                    <li>Sombra will at times be responsible for uploading NFTs to the blockchain but at no time if you elect Sombra to manage this may you hold Sombra responsible for any error arising from upload or sale.</li>
                    <li>For questions, please contact legal@sombra.app</li>
                </ul>
                <h2>For Collectors</h2>
                <ul>
                    <li>Ensure your NFT works</li>
                    <li>Resales are only allowed on the Sombra Network</li>
                    <li>You must follow your local tax law. Sombra will not manage your taxes for you. </li>
                </ul>
                <h2>For Sombra staff</h2>
                <ul>
                    <li>We are always available for the community on Telegram and Discord</li>
                    <li>We will try as best we can to resolve every issue either technical or personal within the Sombra Network</li>
                    <li>We will always maintain full transparency</li>
                    <li>Ensure all smart contract code is open sourced</li>
                    <li>We will never ask anyone for wallet information, private seeds or otherwise compromising financial information. </li>
                    <li>Any admin or staff member who breaks any community rule or harms the project will be removed from the project and held liable for damages.</li>
                </ul>
                <p>More information can be read inside our <a href="/privacy">privacy policy</a> & terms and conditions or please contact support@sombra.app</p>
            </div>
        </div>
    )
}

export default Law;