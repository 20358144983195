import * as React from "react";
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history';
// @ts-ignore
import Web3 from "web3";
import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnectProvider from "@walletconnect/web3-provider";
// @ts-ignore
import Fortmatic from "fortmatic";
import WalletLink from 'walletlink'
import Torus from "@toruslabs/torus-embed";
import Portis from "@portis/web3";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

//custom module
import { apiGetAccountAssets } from "./helpers/api";
import {
  getChainData
} from "./helpers/utilities";
import { IAssetData, NftData } from "./helpers/types";
import { callMint, getItemPrice, getMaxMintAmount, fetchNFT } from "./helpers/web3";

import ScreenWrapper from './components/Home/ScreenWrapper'
import HomeScreen from './pages/HomeScreen'
import StakingScreen from './pages/StakingScreen'
import MintScreen from './pages/MintScreen'
import TermsScreem from './pages/TermsScreem'
import PrivacyScreen from './pages/PrivacyScreen'
import LawScreen from './pages/LawScreen'
import coinBase from '../src/assets/images/coinbase.png'

import { chainId as networkChainId, polygonNetworkInfo } from './sombra_config'
import Footer from './components/Home/Footer'

interface IAppState {
  fetching: boolean;
  address: string;
  web3: any;
  provider: any;
  connected: boolean;
  chainId: number;
  networkId: number;
  assets: IAssetData[];
  showModal: boolean;
  pendingRequest: boolean;
  result: any | null;
  balance: number;
  totalPrice: number;
  amount: number;
  stakeAmount: number;
  stakeRelease: number;
  itemPrice: number;
  maxAmount: number;
  // my state
  isHide: boolean;
  ownNFT: NftData[];
  connectFlag: boolean;
  isloading: boolean;
  mintLoading: number;
  maticPrice: number;
  sombraPrice: number;
}

const INITIAL_STATE: IAppState = {
  fetching: false,
  address: "",
  web3: null,
  provider: null,
  connected: false,
  chainId: 3,
  networkId: 1,
  assets: [],
  showModal: false,
  pendingRequest: false,
  result: null,
  isHide: true,
  totalPrice: 0,
  amount: 1,
  stakeAmount: 1,
  stakeRelease: 1,
  balance: 0,
  itemPrice: 0,
  maxAmount: 0,
  ownNFT: [],
  connectFlag: false,
  isloading: true,
  mintLoading: 0,
  maticPrice: 0,
  sombraPrice: 0,
};

function initWeb3(provider: any) {
  const web3: any = new Web3(provider);

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber
      }
    ]
  });
  return web3;
}

const history = createBrowserHistory();

class App extends React.Component<any, any> {
  // @ts-ignore
  public web3Modal: Web3Modal;
  public state: IAppState;

  constructor(props: any) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };

    this.web3Modal = new Web3Modal({
      network: this.getNetwork(),
      cacheProvider: true,
      providerOptions: this.getProviderOptions(),
      theme: {
        background: "rgba(43, 51, 94, 0.9)",
        main: "rgb(250, 250, 250)",
        secondary: "rgba(250, 250, 250, 0.7)",
        border: "rgba(196, 196, 196, 0.3)",
        hover: "rgba(53, 61, 104, 0.75)"
      }
    });
  }

  public componentDidMount() {
    fetch("https://api.binance.com/api/v3/ticker/price?symbol=MATICUSDT")
      .then((response) => response.json())
      .then((data) => {
        console.log("data.price", data.price)
        this.setState({ maticPrice: parseFloat(data.price).toFixed(2) })
      });
    fetch("https://api.coingecko.com/api/v3/simple/price?ids=sombra-network&vs_currencies=usd")
      .then((response) => response.json())
      .then((data) => {
        let sombraUsd = data['sombra-network'].usd
        console.log("data.price1", sombraUsd)
        this.setState({ sombraPrice: parseFloat(sombraUsd).toFixed(2) })
      });
    AOS.init();
    if (this.web3Modal.cachedProvider) {
      this.onConnect();
    } else {
      console.log("disconnected")
    }
    this.setState({ isHide: true })
  }

  public onConnect = async () => {
    this.setState({ isHide: true })
    const provider = await this.web3Modal.connect();

    await this.subscribeProvider(provider);

    const web3: any = initWeb3(provider);

    const accounts = await web3.eth.getAccounts();

    const address = accounts[0];

    const walletBallance = await web3.eth.getBalance(address)
    const d: number = web3.utils.fromWei(walletBallance.toString(), 'ether')

    const networkId = await web3.eth.net.getId();

    const chainId = await web3.eth.chainId();
    this.setState({ balance: parseFloat(d.toString()).toFixed(4) })
    await this.setState({
      web3,
      provider,
      connected: true,
      address,
      chainId,
      networkId
    });
    this.getAccountAssets();

    if (chainId === networkChainId) {
      this.setState({ connectFlag: true })

      let itemValue = await getItemPrice(web3)
      let etherValue = Web3.utils.fromWei(itemValue.toString(), 'ether');
      let mintedArray = await getMaxMintAmount(web3)
      let maxAmount = mintedArray[1] - mintedArray[0]
      this.setState({ itemPrice: parseFloat(etherValue).toFixed(4), maxAmount: maxAmount, totalPrice: parseFloat(etherValue).toFixed(4) })
      // now fetch all nfts 

      let j = 0
      let keep_going = true;
      let current_nfts: any = [];
      // let tempObj: any = {};
      while (keep_going) {
        let tempObj: any = {};
        try {
          let nft_call: any = await fetchNFT(web3, j, address);
          if (!nft_call) {
            break
          }
          tempObj.tokenId = nft_call[2]
          console.log("what is this", nft_call[2])
          console.log("https://cloudflare-ipfs.com/ipfs/Qmd9SztnUk4V5MM5Ax4eRsYyv5zR6jyswNaVzf6qEsafTo/" + nft_call[2])
          let link = "https://cloudflare-ipfs.com/ipfs/Qmd9SztnUk4V5MM5Ax4eRsYyv5zR6jyswNaVzf6qEsafTo/" + tempObj.tokenId
          fetch(link)
            .then((res) => res.json())
            .then((json) => {
              tempObj.name = json.name
              tempObj.description = json.description
              tempObj.attributes = json.attributes
              // tempObj.image = json.image.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
              tempObj.image = 'https://sombra-test-sins-1.s3.amazonaws.com/images/' +  tempObj.tokenId + '.png'
              console.log("image", tempObj.image)
              // https://sombra-test-sins-1.s3.amazonaws.com/images/

            })

          current_nfts.push(tempObj) // changed this to after break to avoid double of the previsous
        }
        catch (err) {
          console.log(err)
          console.log("likely out of nfts to show")
          keep_going = false;
        }
        j++
      }
      this.setState({ isloading: false })
      console.log("current_nfts", current_nfts)
      await this.setState({ ownNFT: current_nfts })

    } else {
      // window.alert('Choose Polygon Network in your Wallet.')
      this.setState({ connectFlag: false })
      // console.log("Choose Ropsten Network in your Wallet.")
    }
  };

  public add_chain_with_web3 = async () => {
    const provider = window.ethereum;
    console.log("calling add_chain_with_web3")

    let result
    try {
      result = await provider.request({
        method: 'wallet_addEthereumChain',
        params: [polygonNetworkInfo],
      })
      console.log('getting here 22', result)
    } catch (addError) {
      // handle "add" error
      console.log(addError)
    }
    // }
    // handle other "switch" errors
    // }
    console.log('getting here343', result)
  }

  public subscribeProvider = async (provider: any) => {
    if (!provider.on) {
      return;
    }
    provider.on("close", () => this.resetApp());
    provider.on("accountsChanged", async (accounts: string[]) => {
      // await this.setState({ address: accounts[0] });
      // await this.getAccountAssets();
      await this.onConnect()
    });
    provider.on("chainChanged", async (chainId: number) => {
      // const { web3 } = this.state;
      // const networkId = await web3.eth.net.getId();
      // await this.setState({ chainId, networkId });
      // await this.getAccountAssets();
      await this.onConnect()
    });

    provider.on("networkChanged", async (networkId: number) => {
      // const { web3 } = this.state;
      // const chainId = await web3.eth.chainId();
      // await this.setState({ chainId, networkId });
      // await this.getAccountAssets();
      await this.onConnect()
    });
    provider.on("disconnect", (error: { code: number; message: string }) => {
      console.log(error);
    });
  };

  public getNetwork = () => getChainData(this.state.chainId).network;

  public getProviderOptions = () => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: process.env.REACT_APP_INFURA_ID
        }
      },
      'custom-coinbase': {
        display: {
          logo: coinBase,
          name: 'Coinbase',
          description: 'Scan with WalletLink to connect',
        },
        options: {
          appName: 'app', // Your app name
          networkUrl: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
          chainId: 3,
        },
        package: WalletLink,
        connector: async (_: any, options: any) => {
          const { appName, networkUrl, chainId } = options
          const walletLink = new WalletLink({
            appName
          });
          const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
          await provider.enable();
          return provider;
        },
      },
      portis: {
        package: Portis,
        options: {
          id: process.env.REACT_APP_PORTIS_ID
        }
      },
      fortmatic: {
        package: Fortmatic,
        options: {
          key: process.env.REACT_APP_FORTMATIC_KEY
        }
      },
      // mewconnect: {
      //   package: MewConnect, // required
      //   options: {
      //     infuraId: process.env.REACT_APP_INFURA_ID // required
      //   }
      // },
      torus: {
        package: Torus
      }
    };
    return providerOptions;
  };

  public getAccountAssets = async () => {
    const { address, chainId } = this.state;
    this.setState({ fetching: true });
    try {
      // get account balances
      const assets = await apiGetAccountAssets(address, chainId);

      await this.setState({ fetching: false, assets });
    } catch (error) {
      console.error(error); // tslint:disable-line
      await this.setState({ fetching: false });
    }
  };

  public toggleModal = () =>
    this.setState({ showModal: !this.state.showModal });

  public onMintChangeButtonClick = async (dir: number) => {
    const { amount, totalPrice } = this.state;
    console.log("amount++")
    if (dir == -1) {
      console.log("amount++", typeof (amount))
      if (amount <= 1) {
        this.setState({ amount: 1 })
      } else {
        let totalAmonut = amount + dir
        this.setState({ amount: totalAmonut })
        let totalprice = (this.state.itemPrice * totalAmonut).toFixed(4)
        // const etherValue = Web3.utils.fromWei(totalprice.toString(), 'ether');
        this.setState({ totalPrice: totalprice })
        // setTotalPrice(totalAmonut)
      }
    } else if (dir == 1) {
      if (amount == this.state.maxAmount) {
        this.setState({ amount: this.state.maxAmount })
      } else {
        let totalAmonut: number = amount + dir
        this.setState({ amount: totalAmonut })
        let totalprice = (this.state.itemPrice * totalAmonut).toFixed(4)
        this.setState({ totalPrice: totalprice })
      }
    }
  }
  public onStakAmountChangeButtonClick = async (dir: number) => {
    const { stakeAmount } = this.state;
    console.log("amount++")
    if (dir == -1) {
      if (stakeAmount <= 1) {
        this.setState({ stakeAmount: 1 })
      } else {
        let totalAmonut = stakeAmount + dir
        this.setState({ stakeAmount: totalAmonut })
        let totalprice = (this.state.itemPrice * totalAmonut).toFixed(4)
        // const etherValue = Web3.utils.fromWei(totalprice.toString(), 'ether');
        this.setState({ totalPrice: totalprice })
        // setTotalPrice(totalAmonut)
      }
    } else if (dir == 1) {
      let totalAmonut: number = stakeAmount + dir
      this.setState({ stakeAmount: totalAmonut })
    }
  }
  public onStakReleaseChangeButtonClick = async (dir: number) => {
    const { stakeRelease } = this.state;
    console.log("amount++")
    if (dir == -1) {
      console.log("amount++", typeof (stakeRelease))
      if (stakeRelease <= 1) {
        this.setState({ stakeRelease: 1 })
      } else {
        let totalAmonut = stakeRelease + dir
        this.setState({ stakeRelease: totalAmonut })
      }
    } else if (dir == 1) {
      let totalAmonut: number = stakeRelease + dir
      this.setState({ stakeRelease: totalAmonut })
    }
  }

  public onMaxButtonClick = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event.target.value) <= 1) {
      this.setState({ amount: 1 })
    } else if (parseInt(event.target.value) >= this.state.maxAmount) {
      this.setState({ amount: this.state.maxAmount })
    } else {
      this.setState({ amount: parseInt(event.target.value) })
    }
    let totalprice = this.state.itemPrice * parseInt(event.target.value)
    this.setState({ totalPrice: totalprice })
  }

  public onStakeAmountHandle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event.target.value) <= 1) {
      this.setState({ stakeAmount: 1 })
    } else {
      this.setState({ stakeAmount: parseInt(event.target.value) })
    }
  }
  public onStakeReleaseHandle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseInt(event.target.value) <= 1) {
      this.setState({ stakeRelease: 1 })
    } else {
      this.setState({ stakeRelease: parseInt(event.target.value) })
    }
  }

  public onCallMint = async () => {
    console.log("Mint started")
    this.setState({ mintLoading: 1 })
    let contractCall = callMint;

    if (!contractCall) {
      throw new Error(
        `No matching contract calls for functionSig= callGetAuctions`
      );
    }

    const { web3, address, chainId, amount, totalPrice } = this.state;
    try {
      this.setState({ modalStatus: 0 });
      console.log("amount", amount)
      let res = await contractCall(address, chainId, web3, amount, totalPrice);
      // this.setState({auctionList: result});
      // await this.getTotalTokenSold();
      if (res) {
        this.setState({ mintLoading: 2 })
      } else {
        this.setState({ mintLoading: 0 })
      }
    } catch (error) {
      console.log("error=====", error); // tslint:disable-line
      this.setState({ web3, result: null });
    }
  }

  public resetApp = async () => {
    const { web3 } = this.state;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    await this.web3Modal.clearCachedProvider();
    this.setState({ ...INITIAL_STATE });
  };

  public _onHideMenu = (bool: boolean) => {
    this.setState({ isHide: bool })
  }

  public render = () => {
    const {
      address,
      connected,
      chainId,
      isHide,
      balance,
      amount,
      totalPrice,
      maxAmount,
      itemPrice,
      ownNFT,
      connectFlag,
      isloading,
      mintLoading,
      stakeAmount,
      stakeRelease,
      maticPrice,
      sombraPrice,
    } = this.state;
    return (
      <ScreenWrapper>
        <Router history={history}>
          <Switch>
            <Route exact path="/rarity" render={() =>
              <HomeScreen
                connect={this.onConnect}
                isHide={isHide}
                setIsHide={(e: any) => this._onHideMenu(e)}
                connected={connected}
                address={address}
                chainId={chainId}
                killSession={this.resetApp}
                amount={amount}
                totalPrice={totalPrice}
                balance={balance}
                ownNFT={ownNFT}
                add_chain_with_web3={this.add_chain_with_web3}
                connectFlag={connectFlag}
                isloading={isloading}
              />
            } />
            <Route exact path="/stake" render={() =>
              <StakingScreen
                connect={this.onConnect}
                isHide={isHide}
                setIsHide={(e: any) => this._onHideMenu(e)}
                connected={connected}
                address={address}
                chainId={chainId}
                killSession={this.resetApp}
                amount={amount}
                totalPrice={totalPrice}
                balance={balance}
                ownNFT={ownNFT}
                add_chain_with_web3={this.add_chain_with_web3}
                connectFlag={connectFlag}
                isloading={isloading}
              />
            } />
            <Route exact path="/terms" render={() =>
              <TermsScreem />
            } />
            <Route exact path="/privacy" render={() =>
              <PrivacyScreen />
            } />
            <Route exact path="/law" render={() =>
              <LawScreen />
            } />
            <Route exact path="/" render={() =>
              <MintScreen
                connectFlag={connectFlag}
                connect={this.onConnect}
                isHide={isHide}
                setIsHide={(e: any) => this._onHideMenu(e)}
                connected={connected}
                address={address}
                chainId={chainId}
                killSession={this.resetApp}
                onMintChangeButtonClick={this.onMintChangeButtonClick}
                onStakAmountChangeButtonClick={this.onStakAmountChangeButtonClick}
                onStakReleaseChangeButtonClick={this.onStakReleaseChangeButtonClick}
                onMaxButtonClick={(event: React.ChangeEvent<HTMLInputElement>) => this.onMaxButtonClick(event)}
                onStakeAmountHandle={(event: React.ChangeEvent<HTMLInputElement>) => this.onStakeAmountHandle(event)}
                onStakeReleaseHandle={(event: React.ChangeEvent<HTMLInputElement>) => this.onStakeReleaseHandle(event)}
                onCallMint={this.onCallMint}
                amount={amount}
                stakeAmount={stakeAmount}
                stakeRelease={stakeRelease}
                totalPrice={totalPrice}
                balance={balance}
                maxAmount={maxAmount}
                itemPrice={itemPrice}
                maticPrice={maticPrice}
                sombraPrice={sombraPrice}
                add_chain_with_web3={this.add_chain_with_web3}
                mintLoading={mintLoading}
              />
            } />
          </Switch>
          <Footer />
        </Router>
      </ScreenWrapper>
    );
  };
}

export default App;
