import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles'
import RewardAreaContainer from './RewardAreaContainer'

interface Props {
    balance: number,
    onStakAmountChangeButtonClick: (dir: number) => void;
    onStakReleaseChangeButtonClick: (dir: number) => void;    
    onStakeAmountHandle: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onStakeReleaseHandle: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onMaxButtonClick: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onCallMint: () => void;
    totalPrice: number;
    amount: number;
    stakeAmount: number;
    stakeRelease: number;
    maxAmount: number;
    itemPrice: number;
    mintLoading: number;
    connect: () => void,
    add_chain_with_web3: () => void,
    connected: boolean,
    connectFlag: boolean,
}
interface ContainerProps {
    isHide: boolean
}

const BannerContainer = styled.div`
    width: 100%;
`


const RewardArea: React.FC<Props> = ({
    balance,
    totalPrice,
    onMaxButtonClick,
    onCallMint,
    amount,
    maxAmount,
    itemPrice,
    connected,
    connect,
    connectFlag,
    add_chain_with_web3,
    mintLoading,
    stakeAmount,
    stakeRelease,
    onStakAmountChangeButtonClick,
    onStakReleaseChangeButtonClick,
    onStakeAmountHandle,
    onStakeReleaseHandle
}) => {
    return (
        <BannerContainer>
            <RewardAreaContainer
                balance={balance}
                totalPrice={totalPrice}
                onStakAmountChangeButtonClick={onStakAmountChangeButtonClick}
                onStakReleaseChangeButtonClick={onStakReleaseChangeButtonClick}
                onStakeAmountHandle={onStakeAmountHandle}
                onStakeReleaseHandle={onStakeReleaseHandle}
                onMaxButtonClick={onMaxButtonClick}
                onCallMint={onCallMint}
                amount={amount}
                stakeAmount={stakeAmount}
                stakeRelease={stakeRelease}
                maxAmount={maxAmount}
                itemPrice={itemPrice}
                connected={connected}
                connect={connect}
                connectFlag={connectFlag}
                add_chain_with_web3={add_chain_with_web3}
                mintLoading={mintLoading}
            />
        </BannerContainer>
    )
}

export default RewardArea
