import React, {useEffect} from 'react';

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    return (
        <div className="wrapper">
            <img className="collections_header_bg" src="/home_header_bg.png" />
            <div className="policyContent">
                <h1>PRIVACY</h1>
                <p>
                    Sombra Network holds the privacy and security of our community in the highest regard. This Privacy Policy documents how Sombra Network collects information from users and where it goes.
                </p>
                <p>
                    The Sombra Network, including smart contracts deployed to the public blockchain BSC, enable artists and collectors to create, buy, transfer and trade unique digital art, which can also be visualized in browser and mobile (https://sombra.app)
                </p>
                <p>
                    We are a Data Controller of your information. Please email support@sombra.app to speak regarding your information.
                    If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us - support@sombra.app
                </p>
                <h2>Privacy Act 1974 (USA)</h2>
                <p>Sombra adheres to 1974 Privacy law: https://www.justice.gov/opcl/overview-privacy-act-1974-2020-edition</p>
                <h2>CCPA</h2>
                <p>
                    Sombra adheres to CCPA Standards (California Consumer Privacy Act): https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?division=3.&part=4.&lawCode=CIV&title=1.81.5
                    Sombra Network legal basis for collecting and using personal information described in this privacy policy depends on the personal Information we collect and the specific context in which we collect it:
                </p>
                <ul>
                    <li>Sombra needs a legal contract with you (disclaimer agreement at profile creation)</li>
                    <li>You have given Sombra permission to do so (when you sign agreement)</li>
                    <li>Obtaining this information is being done for a valid purpose</li>
                    <li>Sombra maintains all practices and actions inside legal boundaries</li>
                </ul>
                <p>Sombra Network will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>
                <h2>Personal Data Collected</h2>
                <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p>
                <ul>
                    <li>Email address</li>
                    <li>Username or Name</li>
                    <li>Public BSC/ETH Addresses</li>
                    <li>Address, State, Province, ZIP/Postal code, City - (for product orders)</li>
                    <li>Cookies and Usage Data</li>
                </ul>
                <h2>Usage Data and Log Files</h2>
                <p>Sombra Network follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this as part of hosting service analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.</p>
                <p>We may also collect information on how the Service is accessed and used (“Usage Data”). This Usage Data may include information such as your computer’s Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                <p>Sombra Network uses the collected data for various purposes:</p>
                <ul>
                    <li>To provide and maintain Sombra services </li>
                    <li>To notify you about changes to service</li>
                    <li>To allow users to be a part of the community and weigh in on community votes</li>
                    <li>To provide customer care and support</li>
                    <li>Safety</li>
                    <li>To gather feedback and user comments to improve the platform</li>
                    <li>To monitor the platform for illegal activity</li>
                    <li>To detect, prevent and address technical issues</li>
                </ul>
                <h2>Cookies and Web Beacons</h2>
                <p>Like any other website, Sombra Network uses ‘cookies. These cookies are used to store information including visitors’ preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users’ experience by customizing our web page content based on visitors’ browser type and/or other information.</p>
                <p>For more general information on cookies, please read “What Are Cookies”.</p>
                <h2>Privacy Policies</h2>
                <p>
                    You may consult this list to find the Privacy Policy for each of the advertising partners of Sombra Network.
                    Third-party ad servers or ad networks use technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Sombra Network, which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
                    Note that Sombra Network has no access to or control over these cookies that are used by third-party advertisers.
                </p>
                <h2>Third Party Privacy Policies</h2>
                <p>
                    Sombra Network’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
                    You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites.
                </p>
                <h2>Under the age of 18</h2>
                <p>
                    Sombra takes safety very seriously, especially that of minors. While Sombra Network does not allow users under the age of 18 to use its platform, their safety is still very important. We encourage parents and guardians to observe, participate in, and/or monitor and guide minors’ online activity. Sombra will attempt to contact legal guardians of minors found engaging with the profile, but we cannot guarantee it.
                    Sombra Network does not knowingly collect any personally identifiable information from minors or anyone under the age of 18. Sombra cannot control external users from making profiles but if discovered that a user has broken the terms and conditions in any way including age restrictions the profile will be terminated without notice.
                </p>
                <h2>Disclosure Of Data</h2>
                <h2>LEGAL REQUIREMENTS</h2>
                <p>Sombra Network may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                <ul>
                    <li>
                        To comply with a legal obligation
                    </li>
                    <li>
                        To comply with applicable law or regulation, or to comply with law enforcement
                    </li>
                    <li>
                        To protect and defend the rights or property of Sombra Network or to enforce our terms of service.
                    </li>
                    <li>To respond to claims and/or legal process.</li>
                    <li>To prevent or investigate possible wrongdoing in connection with service</li>
                    <li>To protect the personal safety of users </li>
                    <li>To protect against legal liability or unethical behavior for users and Sombra Network</li>
                    <li>Honestly you should just be alright with us disclosing the data as defined in the above terms if needed. Sombra simply holds the data as per our internal needs but if necessary, we reserved the right to release that data for any reason we deem appropriate.</li>
                </ul>
                <p>With your consent, or at your request or direction; or as otherwise set forth in this Policy.</p>
                <h2>Security Of Data</h2>
                <p>
                    The security of your data is important to us but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                </p>
                <p>
                    While our values will not shift, the platform will evolve over time, and this Policy will change to reflect that evolution. See data at the end of this document for the date of the last revision to the Policy. We encourage you to review this Policy periodically to stay informed about our practices.
                </p>
                <h2>Information Security</h2>
                <p>
                    We take safety very seriously. We will take reasonable administrative, physical, and electronic measures to help protect your information from loss, theft, misuse, unauthorized access, disclosure, alteration, or destruction. All that said, no method of transmitting or storing information over the Internet is completely secure. With that in mind, we cannot guarantee the absolute security of your information.
                </p>
                <h2>Transfer Of Data</h2>
                <p>
                    Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those from your jurisdiction.
                </p>
                <p>If you are located outside the USA, please note we obtain and store the data in the cloud via US servers. </p>
                <p>Your consent to the Sombra Privacy Policy at signs up represents your agreement to allow Sombra to collect and store personal information.Sombra will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
                <h2>Online Privacy Policy Only</h2>
                <p>
                    Our Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collected in Sombra Network. This policy is not applicable to any information collected offline or via channels other than this website.
                </p>
                <h2>Consent</h2>
                <p>By using our website, app, and community you hereby consent to our Privacy Policy and agree to its terms.</p>
                <h2>Contact Us</h2>
                <p>
                    Users with questions, concerns or claims can be made by contacting us at support@sombra.app
                </p>
                <p>
                    Sombra Network LLC is a registered legal entity of the United States
                </p>
                <br />
                <p>
                    © 2021 Sombra Network LLC
                </p>
                <p>
                    Questions email support@sombra.app
                </p>
            </div>
        </div>
    )
}

export default Privacy;