import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../styles'
import Dialog from '@material-ui/core/Dialog';

import close from '../../assets/images/close.png'

import {
    createTheme,
    createStyles,
    makeStyles,
    Theme,
} from '@material-ui/core/styles';



interface Props {
    ownNFT: Array<any>;
}
interface ContainerProps {
    isHide: boolean
}

const BannerContainer = styled.div`
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    align-items:space-between;
    justify-content:center;
`
const ItemContainer = styled.div`
    width: 300px;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    margin: 20px 50px;
    &:hover {
        transform: scale(1.05);
        cursor: pointer;
    }
`
const NftImage = styled.img`
    width: 300px;
    height: 300px;
    align-self: center;
`
const Title = styled.p`
    font-size: 20px;
    text-align:center;
    color:white;
`
const LoadingImage = styled.img`
    width: 100px;
    height: 100px;
`
const ModalContent = styled.div`
    position: relative;
    width: 100%;
    height: 500px;
`
const ModalCloseBtn = styled.div`
    width: 100%;
    height: 30px;
    background-color: white;
    display: flex;
    align-items:center;
    justify-content: flex-end;
    &:hover{
        cursor: pointer;
    }
`
const CloseBtn = styled.img`
    margin-top: 15px;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    padding: 5px;
    border-radius: 15px;
    border: 1px solid gray;
`
const SideContent = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
`
const AvartarImg1 = styled.img`
    width: 80%;
    margin: auto;
    display:flex;
`
const Content = styled.div`
    display:flex;
    flex-direction: row;
`

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 689,
            width: 1000,
            marginTop: 200
        },
        paper: {
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            borderRadius: 10,
            padding: theme.spacing(2, 4, 3),
            width: '100%'
        },
        dialog: {
            maxWidth: 600,
            height: 689,
        },
        itemTitle: {
            textAlign: 'center',
            fontFamily: 'PlusJakartaSans-Regular',
            fontSize: 16,
            fontWeight: 700
        }
    }),
);


const HomeContainer: React.FC<Props> = ({
    ownNFT
}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [modalContent, setModalContent] = useState<any>({});

    useEffect(() => {
        console.log("ownNFT", ownNFT[0]?.image)
    }, [ownNFT]);

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = (item:any) => {
        setModalContent(item)
        setOpen(true);
    };

    return (
        <BannerContainer>
            {
                ownNFT.map((item, index) => {
                    return (
                        <ItemContainer key={index} onClick={() => { handleOpen(item) }}>
                            <NftImage src={item.image} alt='' />
                            <Title>{item.name}</Title>
                            <Title>{item.description}</Title>
                        </ItemContainer>
                    )
                })
            }
            <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose}>
                <ModalContent>
                    <ModalCloseBtn onClick={handleClose} id="transition-modal-description">
                        <CloseBtn src={close} />
                    </ModalCloseBtn>
                    <Content>
                        <SideContent>
                            <AvartarImg1 src={modalContent.image} alt='' />
                        </SideContent>
                        <SideContent>
                            <h2 style={{ fontSize: 31, textAlign: 'center' }}>{modalContent.name}</h2>
                            <h2 style={{ fontSize: 31, textAlign: 'center' }}>{modalContent.description}</h2>
                        </SideContent>
                    </Content>
                </ModalContent>
            </Dialog>
        </BannerContainer>
    )
}

export default HomeContainer
