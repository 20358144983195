import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styles'
import BannerContent from './BannerContent'

interface Props {
    balance: number,
    onMintChangeButtonClick: (dir: number) => void;
    onMaxButtonClick: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onCallMint: () => void;
    totalPrice: number;
    amount: number;
    maxAmount: number;
    itemPrice: number;
    sombraPrice: number;
    maticPrice: number;
    connect: () => void,
    add_chain_with_web3: () => void,
    connected: boolean,
    connectFlag: boolean,
}
interface ContainerProps {
    isHide: boolean
}

const BannerContainer = styled.div`
    width: 100%;
`


const TopContainer: React.FC<Props> = ({
    balance,
    totalPrice,
    onMintChangeButtonClick,
    onMaxButtonClick,
    onCallMint,
    amount,
    maxAmount,
    itemPrice,
    connected,
    connect,
    connectFlag,
    add_chain_with_web3,
    sombraPrice,
    maticPrice,
}) => {
    return (
        <BannerContainer>
            <BannerContent
                balance={balance}
                totalPrice={totalPrice}
                onMintChangeButtonClick={onMintChangeButtonClick}
                onMaxButtonClick={onMaxButtonClick}
                onCallMint={onCallMint}
                amount={amount}
                maxAmount={maxAmount}
                itemPrice={itemPrice}
                connected={connected}
                sombraPrice={sombraPrice}
                maticPrice={maticPrice}
                connect={connect}
                connectFlag={connectFlag}
                add_chain_with_web3={add_chain_with_web3}
            />
        </BannerContainer>
    )
}

export default TopContainer
