import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import BuccaneerHeader from '../components/Home/BuccaneerHeader'
import TopContainer from '../components/Home/TopContainer'
import Phase_Biomatics from '../components/Home/Phase_Biomatics'
import RewardArea from '../components/Home/RewardArea'
import LoadMapContainer from '../components/Home/LoadMapContainer'
import { colors } from "../styles";
import Container from '@material-ui/core/Container';
import { countDownData, openSeaLink } from '../sombra_config'
import sombraImage from '../assets/images/sombraImage.png'
import polygonImage from '../assets/images/polygonImage1.png'
import telegramImage from '../assets/images/telegramImage.png'
import discordImage from '../assets/images/discordImage.png'

interface Props {
    connect: () => void;
    setIsHide: (e: any) => void;
    killSession: () => void;
    onMintChangeButtonClick: (dir: number) => void;
    onStakAmountChangeButtonClick: (dir: number) => void;
    onStakReleaseChangeButtonClick: (dir: number) => void;
    onMaxButtonClick: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onStakeAmountHandle: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onStakeReleaseHandle: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onCallMint: () => void;
    add_chain_with_web3: () => void;
    isHide: boolean;
    connected: boolean;
    connectFlag: boolean;
    address: string;
    chainId: number;
    amount: number;
    stakeAmount: number;
    stakeRelease: number;
    totalPrice: number;
    balance: number;
    maxAmount: number;
    itemPrice: number;
    mintLoading: number;
    sombraPrice: number;
    maticPrice: number;
}

const MintContainer = styled.div`

`
const ButtonItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    background-color: #D900F9;
    width: 100%;
    justify-content: space-between;
    align-items:center;
    padding: 0 50px;
    @media (max-width: 1035px) {
        padding: 10px 20px;
    }
`
const ButtonItemsContainer1 = styled.div`
    position: fixed;
    top: 0;
    display: none;
    flex-wrap: wrap;
    background-color: #D900F9;
    width: 100%;
    justify-content: space-between;
    align-items:center;
    padding: 0 50px;
    z-index: 999;
    @media (max-width: 1035px) {
        padding: 5px 20px;
    }
`
const MintButton = styled.button`
    background-color: #D900F9;
    margin: 0;
    height: 48px;
    font-size: 14px;
    color: white;
    font-family: Orbitron-Regular;
    padding: 0;
    text-align: start;
    font-weight: 700;
    @media (max-width: 1035px) {
        height: 30px;
        width: 50px;
        font-size: 8px;
        font-weight: 400;
    }
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
    `
const MintButton2 = styled.button`
    background-color: #D900F9;
    margin: 0;
    padding-left: 3px;
    height: 48px;
    font-size: 14px;
    color: white;
    font-family: Orbitron-Regular;
    font-weight: 700;
    @media (max-width: 1035px) {
        height: 30px;
        font-size: 8px;
        font-weight: 400;
    }
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const TokenValueArea = styled.div` 
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;
    @media (max-width: 1035px) {
        display: none;
    }
`
const TokenValueArea1 = styled.div` 
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;
    display: none;
    @media (max-width: 1035px) {
        display: flex;
    }
`
const TokenValueArea2 = styled.div` 
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;
`
const MintButton1 = styled.button`
    background-color: #D900F9;
    margin: 0;
    height: 48px;
    font-size: 18px;
    color: white;
    font-family: Gilroy-Light;
    padding: 0;
    @media (max-width: 1035px) {
        height: 30px;
        font-size: 10px;
    }
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const BannerContainer = styled.div`
    background-color: rgb(${colors.black});
    background-image: url(/images/background.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 120vh;
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    position: relative;
    padding-bottom: 200px;
    @media screen and (max-width: 1550px) {
        min-height: 100vh;
    }
`
const CountDownTxt = styled.p`
    color: #FFFFFF;
    font-size: 48px;
    text-align:center;
    display: inline-block;
    font-weight:700;
    margin-bottom: 50px;
    margin-top: 0;
    width: 100%;
    @media (max-width: 660px) {
        font-size: 36px;
    }
`
const MediumImage2 = styled.img`
    width: 22px;
    margin-right: 7px;
    @media (max-width: 1035px) {
        width: 16px;
    }
    &:hover {
        transform: scale(1.1);
    }
`
const TelegramImage = styled.img`
    width: 21px;
    margin-right: 5px;
    @media (max-width: 1035px) {
        width: 12px;
        margin-right: 2px;
    }
`
const DiscordImage = styled.img`
    width: 35px;
    @media (max-width: 1035px) {
        width: 18px;
    }
`

const MintScreen = (props: Props) => {
    let history = useHistory()
    const [daysInfo, setDaysInfo] = useState(0);
    const [hoursInfo, setHoursInfo] = useState(0);
    const [minutesInfo, setMinutesInfo] = useState(0);
    const [secondsInfo, setSecondsInfo] = useState(0);
    const [expiredFlag, setExpiredFlag] = useState(false);
    const [topBarFix, setTopBarFix] = useState(false)

    useEffect(() => {
        if (countDownData.countdown) {
            var countDownDate = countDownData.countDownDate
            var x = setInterval(function () {

                // Get today's date and time
                var now = new Date().getTime();

                // Find the distance between now and the count down date
                var distance = countDownDate - now;

                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setDaysInfo(days);
                setHoursInfo(hours);
                setMinutesInfo(minutes);
                setSecondsInfo(seconds);

                // Display the result in the element with id="demo"

                // If the count down is finished, write some text
                if (distance < 0) {
                    clearInterval(x);
                    setExpiredFlag(true);
                }
            }, 1000);
        }
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, [window.scrollY])


    // let scrollElem = React.createRef();
    let scrollElem = useRef(null)

    let flag = false

    const handleScroll = () => {
        // console.log('888', topBarFix)
        // if (scrollElem instanceof HTMLDivElement)
        //     console.log('===', scrollElem?.offsetTop)
        // if (scrollElem instanceof HTMLDivElement && scrollElem?.offsetTop > document.body.scrollTop) {
        //     console.log("top+++++++++=")
        // }
        var d: HTMLElement | null = document.getElementById("myID");
        var dd: HTMLElement | null = document.getElementById("fixedID");
        var topPos: number | undefined = d?.offsetTop;
        if (dd && topPos && window.scrollY > topPos) {
            dd.style.display = 'flex';
        } else if (dd && topPos && window.scrollY < topPos) {
            dd.style.display = 'none';
        }
    }

    const warning = () => {
        alert("Not quite ready yet")
    }

    // if (countDownData.countdown) {
    //     return (
    //         <BannerContainer>
    //             {
    //                 expiredFlag ?
    //                     <CountDownTxt>EXPIRED</CountDownTxt> :
    //                     <Container>
    //                         <CountDownTxt><span style={{ color: "#fc5eff" }}>SINS</span> Drop In:</CountDownTxt>
    //                         <CountDownTxt>{daysInfo} <span style={{ fontSize: 25 }}>Days</span> {hoursInfo} <span style={{ fontSize: 25 }}>Hours</span> {minutesInfo} <span style={{ fontSize: 25 }}>Mins</span> {secondsInfo} <span style={{ fontSize: 25 }}>Secs</span></CountDownTxt>
    //                     </Container>
    //             }
    //         </BannerContainer>
    //     )
    // }

    const navigateTo = (targetPath: string) => {
        history.push(`/${targetPath}`)
    }

    return (
        <MintContainer>
            {/* <BuccaneerHeader
                connect={props.connect}
                isHide={props.isHide}
                setIsHide={(e: any) => props.setIsHide(e)}
                connected={props.connected}
                address={props.address}
                chainId={props.chainId}
                killSession={props.killSession}
            /> */}
            {/* <WholeCard> */}
            <TopContainer
                connect={props.connect}
                connected={props.connected}
                balance={props.balance}
                amount={props.amount}
                totalPrice={props.totalPrice}
                onMintChangeButtonClick={props.onMintChangeButtonClick}
                onMaxButtonClick={props.onMaxButtonClick}
                onCallMint={props.onCallMint}
                maxAmount={props.maxAmount}
                itemPrice={props.itemPrice}
                connectFlag={props.connectFlag}
                add_chain_with_web3={props.add_chain_with_web3}
                sombraPrice={props.sombraPrice}
                maticPrice={props.maticPrice}
            />
            {/* <ButtonItemsContainer ref={scrollElem as React.RefObject<HTMLDivElement>}> */}
            <ButtonItemsContainer1 id="fixedID">
                <MintButton onClick={e => {
                    const mint = document.querySelector('#mintArea');
                    if (mint) {
                        mint.scrollIntoView({ behavior: 'smooth' });
                    }
                }}>Mint</MintButton>
                {/* <MintButton onClick={() => {
                    window.open(openSeaLink, '_blank')
                }}>OpenSea</MintButton> */}
                {/* <MintButton onClick={() => navigateTo("rarity")}>Rarity</MintButton> */}
                <MintButton onClick={warning}>Rarity</MintButton>
                <MintButton onClick={e => {
                    const mint = document.querySelector('#roadMap');
                    if (mint) {
                        mint.scrollIntoView({ behavior: 'smooth' });
                    }
                }}>Roadmap</MintButton>
                <MintButton onClick={e => {
                    const mint = document.querySelector('#team');
                    if (mint) {
                        mint.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }}>Team</MintButton>
                <TokenValueArea1 onClick={() => {
                    window.open("https://discord.gg/gfvwXkzgAT", '_blank')
                }}>
                    <DiscordImage src={discordImage} alt="" />
                    <MintButton2>Discord</MintButton2>
                </TokenValueArea1>
                <TokenValueArea1 onClick={() => {
                    window.open("https://coinmarketcap.com/currencies/polygon/", '_blank')
                }}>
                    <MediumImage2 src={polygonImage} alt="" />
                    <MintButton1>MATIC = ${props.maticPrice}</MintButton1>
                </TokenValueArea1>
                {/* <TokenValueA onClick={() => {
                        window.open("https://coinmarketcap.com/currencies/polygon/", '_blank')
                    }}rea>
                    <MediumImage2 src={polygonImage} alt="" />
                    <MintButton1>MATIC = ${props.maticPrice}</MintButton1>
                </TokenValueArea> */}
                <MintButton onClick={e => {
                    const mint = document.querySelector('#collect');
                    if (mint) {
                        mint.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }}>Collect</MintButton>
                <MintButton onClick={e => {
                    const mint = document.querySelector('#smbrToken');
                    if (mint) {
                        mint.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }}>SMBR</MintButton>
                <MintButton onClick={e => {
                    const mint = document.querySelector('#reward');
                    if (mint) {
                        mint.scrollIntoView({ behavior: 'smooth' });
                    }
                }}>Rewards</MintButton>
                <MintButton onClick={e => {
                    const mint = document.querySelector('#staking');
                    if (mint) {
                        mint.scrollIntoView({ behavior: 'smooth' });
                    }
                }}>Staking</MintButton>
                <TokenValueArea onClick={() => {
                    window.open("https://discord.gg/gfvwXkzgAT", '_blank')
                }}>
                    <DiscordImage src={discordImage} alt="" />
                    <MintButton2>Discord</MintButton2>
                </TokenValueArea>
                <TokenValueArea2 onClick={() => {
                    window.open("https://t.me/sombranetwork", '_blank')
                }}>
                    <TelegramImage src={telegramImage} alt="" />
                    <MintButton2>Telegram</MintButton2>
                </TokenValueArea2>
                <TokenValueArea onClick={() => {
                    window.open("https://coinmarketcap.com/currencies/polygon/", '_blank')
                }}>
                    <MediumImage2 src={polygonImage} alt="" />
                    <MintButton1>MATIC = ${props.maticPrice}</MintButton1>
                </TokenValueArea>
                <TokenValueArea2 onClick={() => {
                    window.open("https://coinmarketcap.com/currencies/sombra-network/", '_blank')
                }}>
                    <MediumImage2 src={sombraImage} alt="" />
                    <MintButton1>SMBR = ${props.sombraPrice}</MintButton1>
                </TokenValueArea2>
            </ButtonItemsContainer1>
            <ButtonItemsContainer ref={scrollElem} id="myID">
                <MintButton onClick={e => {
                    const mint = document.querySelector('#mintArea');
                    if (mint) {
                        mint.scrollIntoView({ behavior: 'smooth' });
                    }
                }}>Mint</MintButton>
                {/* <MintButton onClick={() => {
                    window.open(openSeaLink, '_blank')
                }}>OpenSea</MintButton> */}
                <MintButton onClick={warning}>Rarity</MintButton>
                <MintButton onClick={e => {
                    const mint = document.querySelector('#roadMap');
                    if (mint) {
                        mint.scrollIntoView({ behavior: 'smooth' });
                    }
                }}>Roadmap</MintButton>
                <MintButton onClick={e => {
                    const mint = document.querySelector('#team');
                    if (mint) {
                        mint.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }}>Team</MintButton>
                <TokenValueArea1 onClick={() => {
                    window.open("https://discord.gg/gfvwXkzgAT", '_blank')
                }}>
                    <DiscordImage src={discordImage} alt="" />
                    <MintButton2>Discord</MintButton2>
                </TokenValueArea1>
                <TokenValueArea1 onClick={() => {
                    window.open("https://coinmarketcap.com/currencies/polygon/", '_blank')
                }}>
                    <MediumImage2 src={polygonImage} alt="" />
                    <MintButton1>MATIC = ${props.maticPrice}</MintButton1>
                </TokenValueArea1>
                <MintButton onClick={e => {
                    const mint = document.querySelector('#collect');
                    if (mint) {
                        mint.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }}>Collect</MintButton>
                <MintButton onClick={e => {
                    const mint = document.querySelector('#smbrToken');
                    if (mint) {
                        mint.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }}>SMBR</MintButton>
                <MintButton onClick={e => {
                    const mint = document.querySelector('#reward');
                    if (mint) {
                        mint.scrollIntoView({ behavior: 'smooth' });
                    }
                }}>Rewards</MintButton>
                <MintButton onClick={e => {
                    const mint = document.querySelector('#staking');
                    if (mint) {
                        mint.scrollIntoView({ behavior: 'smooth' });
                    }
                }}>Staking</MintButton>
                <TokenValueArea onClick={() => {
                    window.open("https://discord.gg/gfvwXkzgAT", '_blank')
                }}>
                    <DiscordImage src={discordImage} alt="" />
                    <MintButton2>Discord</MintButton2>
                </TokenValueArea>
                <TokenValueArea2 onClick={() => {
                    window.open("https://t.me/sombranetwork", '_blank')
                }}>
                    <TelegramImage src={telegramImage} alt="" />
                    <MintButton2>Telegram</MintButton2>
                </TokenValueArea2>
                <TokenValueArea onClick={() => {
                    window.open("https://coinmarketcap.com/currencies/polygon/", '_blank')
                }}>
                    <MediumImage2 src={polygonImage} alt="" />
                    <MintButton1>MATIC = ${props.maticPrice}</MintButton1>
                </TokenValueArea>
                <TokenValueArea2 onClick={() => {
                    window.open("https://coinmarketcap.com/currencies/sombra-network/", '_blank')
                }}>
                    <MediumImage2 src={sombraImage} alt="" />
                    <MintButton1>SMBR = ${props.sombraPrice}</MintButton1>
                </TokenValueArea2>
            </ButtonItemsContainer>
            <Phase_Biomatics
                connect={props.connect}
                connected={props.connected}
                balance={props.balance}
                amount={props.amount}
                totalPrice={props.totalPrice}
                onMintChangeButtonClick={props.onMintChangeButtonClick}
                onMaxButtonClick={props.onMaxButtonClick}
                onCallMint={props.onCallMint}
                maxAmount={props.maxAmount}
                itemPrice={props.itemPrice}
                connectFlag={props.connectFlag}
                add_chain_with_web3={props.add_chain_with_web3}
                mintLoading={props.mintLoading}
            />
            <LoadMapContainer />
            <RewardArea
                connect={props.connect}
                connected={props.connected}
                balance={props.balance}
                amount={props.amount}
                stakeAmount={props.stakeAmount}
                stakeRelease={props.stakeRelease}
                totalPrice={props.totalPrice}
                onStakReleaseChangeButtonClick={props.onStakReleaseChangeButtonClick}
                onStakAmountChangeButtonClick={props.onStakAmountChangeButtonClick}
                onMaxButtonClick={props.onMaxButtonClick}
                onStakeReleaseHandle={props.onStakeReleaseHandle}
                onStakeAmountHandle={props.onStakeAmountHandle}
                onCallMint={props.onCallMint}
                maxAmount={props.maxAmount}
                itemPrice={props.itemPrice}
                connectFlag={props.connectFlag}
                add_chain_with_web3={props.add_chain_with_web3}
                mintLoading={props.mintLoading}
            />
        </MintContainer>
    )
}

export default MintScreen
