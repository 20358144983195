import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BuccaneerHeader from '../components/Home/BuccaneerHeader'
import HomeContainer from '../components/Home/HomeContainer'
import { colors } from "../styles";
import { useHistory } from 'react-router-dom'
import loading from '../assets/images/loading.gif'

interface Props {
    connect: () => void;
    setIsHide: (e: any) => void;
    killSession: () => void;
    isHide: boolean;
    connected: boolean;
    address: string;
    chainId: number;
    amount: number;
    totalPrice: number;
    balance: number;
    ownNFT: Array<any>;
    add_chain_with_web3: () => void;
    connectFlag: boolean;
    isloading: boolean;
}

const MintContainer = styled.div`
    background-color: rgb(${colors.black});
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`

const LoadingImage = styled.img`
    width: 100px;
    height: 100px;
    margin-bottom:150px;
`
const ConnectButton = styled.button`
    border: none;
    background: white;
    color: black;
    font-size:  30px;
    border-radius: 10px;
    font-weight: bold;
    box-shadow: none;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 50px;
    height: 50px;
    &:hover {
        box-shadow: 0 4px 8px 0 rgba(${colors.border});
    }
`

const MintScreen = (props: Props) => {
    const [nftData, setNftData] = useState<any[]>([]);
    useEffect(() => {
        window.scrollTo(0, 0);
        setNftData(props.ownNFT)
    }, [props.ownNFT]);

    let history = useHistory()

    const networkWarning = () => {
        alert("Not quite ready yet")
    }

    return (
        <MintContainer>
            {/* <BuccaneerHeader
                connect={props.connect}
                isHide={props.isHide}
                setIsHide={(e: any) => props.setIsHide(e)}
                connected={props.connected}
                address={props.address}
                chainId={props.chainId}
                killSession={props.killSession}
            /> */}
            {/* <WholeCard> */}
            {
                props.connected ?
                    props.connectFlag ?
                        props.isloading ?
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh' }}>
                                <LoadingImage src={loading} alt='' />
                            </div> :
                            nftData.length === 0 ?
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh' }}>
                                    <ConnectButton style={{ marginBottom: 250 }} onClick={() => { history.push(`/`) }}>Go to mint</ConnectButton>
                                </div> :
                                <HomeContainer ownNFT={nftData} /> :
                        <ConnectButton onClick={props.add_chain_with_web3}>Connect to polygon</ConnectButton> :
                    // <ConnectButton onClick={props.connect}>Connect</ConnectButton>
                    <ConnectButton onClick={networkWarning}>Connect</ConnectButton>
            }

        </MintContainer>
    )
}

export default MintScreen
