export const countDownData: any = {
    countdown: true,
    countDownDate: new Date("Mar 15, 2022 21:00:00").getTime(),
}

export const chainId: any = 80001;
export const openSeaLink: string = 'https://opensea.io/collection/sinsofshadowofficial'

export const polygonNetworkInfo: any = {
    chainId: '0x13881',
    rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
    chainName: "Mumbai Testnet",
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18
    },
    blockExplorerUrls: ["https://polygonscan.com/"]
}