import { DAI_CONTRACT, MINT_CONTRACT, NFT_CONTRACT } from '../constants'

export function getDaiContract(chainId: number, web3: any) {
  const dai = new web3.eth.Contract(
    DAI_CONTRACT[chainId].abi,
    DAI_CONTRACT[chainId].address
  )
  return dai
}

export function callBalanceOf(address: string, chainId: number, web3: any) {
  return new Promise(async (resolve, reject) => {
    const dai = getDaiContract(chainId, web3)

    await dai.methods
      .balanceOf(address)
      .call(
        { from: '0x0000000000000000000000000000000000000000' },
        (err: any, data: any) => {
          if (err) {
            reject(err)
          }

          resolve(data)
        }
      )
  })
}

export function callTransfer(address: string, chainId: number, web3: any) {
  return new Promise(async (resolve, reject) => {
    const dai = getDaiContract(chainId, web3)

    await dai.methods
      .transfer(address, '1')
      .send({ from: address }, (err: any, data: any) => {
        if (err) {
          reject(err)
        }

        resolve(data)
      })
  })
}

export async function callMint(address: string, chainId: number, web3: any, amount: number, totalPrice: number) {
  try {
    console.log('contract  callMint', totalPrice)
    totalPrice = totalPrice * 1.05
    const contract = new web3.eth.Contract(MINT_CONTRACT.abi, MINT_CONTRACT.address)
    let tempValue = web3.utils.toWei(totalPrice.toString(), "ether")
    console.log(contract, amount, tempValue, typeof (tempValue))
    await contract.methods.mint_multiple_with_nativecoin(amount, address).send({ from: address, value: web3.utils.toWei(totalPrice.toString(), "ether") })
    return true
  } catch (error) {
    console.log('errorr', error)
    return false;
  }
}

export async function getItemPrice(web3: any) {
  try {
    const contract = new web3.eth.Contract(MINT_CONTRACT.abi, MINT_CONTRACT.address)
    let value = await contract.methods.getNativePrice().call()
    return value;
  } catch (error) {
    console.log('errorr', error)
  }
}

export async function getMaxMintAmount(web3: any) {
  try {
    const contract = new web3.eth.Contract(MINT_CONTRACT.abi, MINT_CONTRACT.address)
    let maxAmount = await contract.methods.getAmmountMinted().call()
    return maxAmount;
  } catch (error) {
    console.log('errorr', error)
  }
}

export async function fetchNFT(web3: any, j: any, account: string) {


  let contract_token_1 = new web3.eth.Contract(NFT_CONTRACT.abi, NFT_CONTRACT.address)
  let current_nft
  let owners_balance

  try {

    current_nft = await contract_token_1.methods.tokenOfOwnerByIndex(account, j).call()
    owners_balance = await contract_token_1.methods.balanceOf(account).call()
    // nft_info = await contract_token_1.methods.nft_info(current_nft).call()

  }
  catch (err) {
    // console.log(err)
    return false
  }
  try {
    if (current_nft) {
      let nft_uri = await contract_token_1.methods.tokenURI(current_nft).call()

      let just_hash = nft_uri.slice(7) // change to appropriate length 

      let meta_data_link = "https://cloudflare-ipfs.com/ipfs/" + just_hash

      return ([meta_data_link, owners_balance, current_nft]);
    }

  }
  catch (err) {
    console.log("uri/return failed", err)
    return false
  }
}
