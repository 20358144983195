import React, { useState, useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import styled from 'styled-components'
import { countDownData, openSeaLink } from '../../sombra_config'
import {
    createTheme,
    makeStyles,
    ThemeProvider,
} from '@material-ui/core/styles';
import { colors } from '../../styles'
// import logoImage from '../../assets/images/logo.png'
import sombraImage from '../../assets/images/sombraImage.png'
import logoImage from '../../assets/images/logoImage.png'
import unrealImage from '../../assets/images/unrealImage.png'
import mayaImage from '../../assets/images/mayaImage.png'
import sombraLeftImage from '../../assets/images/sombraLeftImage.png'
import homeVideo from '../../assets/videos/LOADING.mp4'
import loopMobile from '../../assets/videos/loopMobile.mp4'

// Material ui style
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    textCont: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    imgCont: {
        display: 'flex',
        justifyContent: 'center',
    },
    progress: {
        width: '80%',
        height: 14,
        borderRadius: 7,
    },
    imgContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    topArea: {
        marginBottom: 80,
        [theme.breakpoints.down('md')]: {
            marginBottom: 40,
        },
    },
    stackarea: {
        backgroundColor: '#8F4AFF',
        [theme.breakpoints.down('md')]: {
            marginTop: -40,
        },
    },
    calcArea: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center',
        },
    }
}));

// styled-components 
const LogoImg = styled.img`
    width: 72px;
    height: 84px;
    position: absolute;
    top: 41px;
    left: 50px;
    z-index: 999;
    @media screen and (max-width: 930px) {
        width: 35px;
        height: 44.5px;
        top: 21px;
        left: 20px;
    }
    &:hover {
        cursor: pointer;
    }
`
const TopImageArea = styled.div` 
    display: flex;
    flex-direction: column;
`
const SombraImg = styled.img`
    width: 71px;
    height: 67px;
    z-index: 999;
    @media screen and (max-width: 1300px) {
        width: 40px;
        height: 40px;
    }
    @media screen and (max-width: 930px) {
        width: 21px;
        height: 21px;
    }
    &:hover {
        cursor: pointer;
    }
`
const UnrealImg = styled.img`
    width: 60px;
    height: 71px;
    margin-top: 10px;
    z-index: 999;
    @media screen and (max-width: 1300px) {
        width: 40px;
        height: 50px;
    }
    @media screen and (max-width: 930px) {
        width: 21px;
        height: 25px;
    }
    &:hover {
        cursor: pointer;
    }
`
const SombraLeftImg = styled.img`
    width: 3.5px;
    height: 141px;
    margin-right: 20px;
    margin-left: 20px;
    @media screen and (max-width: 1550px) {
        height: 100px;
    }
    @media screen and (max-width: 930px) {
        height: 50px;
        margin-right: 5px;
        margin-left: 5px;
    }
`
const Title = styled.h1`
    font-size: 36px;
    color: white;
    font-family: Gilroy-Light;
    font-weight: 100;
    text-align: right;
    margin: 0;
    @media screen and (max-width: 1300px) {
        font-size: 25px;
    }
    @media screen and (max-width: 930px) {
        font-size: 10px;
    }   
`
const TopLeft = styled.div`
    position: absolute;
    right: 22px;
    top: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    @media screen and (max-width: 930px) {
        right: 10px;
        top: 20px;
    }   
`
const OutlineTitle = styled.p`
    font-size: 72px;
    font-weight: 400;
    color: white;
    font-family: fenwick-outline;
    margin: 0;
    padding-top: 40px; 
    letter-spacing: 0.2em;
    text-shadow: 4px 4px rgba(${colors.shadow});
    text-align:center;
    @media screen and (max-width: 1300px) {
        font-size: 50px;
    }
    @media screen and (max-width: 930px) {
        font-size: 18px;
        padding-top: 20px;
        letter-spacing: 0.11em;
    }
}
`
// const ConnectButton = styled(OutlineButton)`
//     border: none;
//     background: white;
//     color: black;
//     font-weight: bold;
//     box-shadow: none;
//     &:hover {
//         box-shadow: 0 4px 8px 0 rgba(${colors.border});
//     }
// `
const BannerContainer = styled.div`
    background-color: rgb(${colors.black});
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    position: relative;
`
const LineArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: auto 0;
    width: 100%;
    flex-wrap: wrap;
`
const LineTitle = styled.p`
    color: white;
    font-size: 24px;
    text-align:center;
    font-family: Gilroy-BoldItalic;
    margin:0;
    @media (max-width: 660px) {
        font-size: 16px;
    }
`
const LineItem = styled.div`
    width: 2px;
    height: 70px;
    background-color: white;
    margin: 5px 12px;
`
const ActiveLineItem = styled.div`
    width: 4px;
    height: 70px;
    background-color: #D900F9;
    margin: 5px 0;
    margin: 5px 11px;
`
const ButtonArea = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 50px;
    @media (max-width: 930px) {
        padding-bottom: 10px;
    }
`
const LeftButtonArea = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 100%;
    padding-bottom: 50px;
    @media (max-width: 1550px) {
        padding-bottom: 20px;
    }
`
const RightButtonArea = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding-bottom: 50px;
    @media (max-width: 1550px) {
        padding-bottom: 30px;
    }
`
const MainContent = styled.div`
    display: flex;
    position: absolute;
    top: 0px;
    flex-direction: column;
    width: 100%;
    justify-content:space-between;
    align-items:center;
    height: 100%;
    padding: 0 50px;
    @media screen and (max-width: 1550px) {
        height: 100%;
    }
    @media screen and (max-width: 930px) {
        height: 100%;
        padding: 0 10px;
    }
`
const CalContent2 = styled.div`
    width: 100%;
    max-width: 400px;
    // border-bottom: 2px solid #9F6FED;
    flex-direction: row;
    justify-content:space-between;
    display: flex;
    align-items:center;
    background-color: #8F4AFF;
    margin-top: 10px;
    border-radius: 100%;
    font-weight: bold;
    
`
const Calculator = styled.div`
    // width: 100px;
    // margin: 0 auto;
    flex-direction: row;
    justify-content:space-between;
    align-items:center;
    display: flex;
`
const CalContent1 = styled.div`
    width: 100%;
    margin: 0 auto;
    flex-direction: row;
    justify-content:space-between;
    display: flex;
    align-items:center;
`
const ButtonItem = styled.a`
    color: white;
    font-size: 18px;
    align-self: center;
    font-family: Orbitron-Regular;
    font-weight: 700;
    margin: 0 30px;
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(255,255,255,1);
    }
`
const RightButtonItem = styled.p`
    color: white;
    font-size: 16px;
    align-self: center;
    font-family: Orbitron-Regular;
    font-weight: 700;
    @media screen and (max-width: 930px) {
        font-size: 9px;
    }
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const RightButtonItemArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    width: 255px;
    height: 71px;
    background-image: url(/images/mintButtonImage.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-right: 50px;
    @media screen and (max-width: 930px) {
        display: none;
    }
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const WatchButtonItemArea = styled.div`
    display: flex;
    align-self: end;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    width: 257px;
    height: 60px;
    background-image: url(/images/watchButtonImage.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media screen and (max-width: 930px) {
        width: 150px;
        height: 35px;
    }
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const LogoTitle5 = styled.a`
    color: white;
    font-size: 14px;
    text-align:center;
    display: inline-block;
    &:hover {
        cursor: pointer;
    }
`
const LogoTitle = styled.p`
    color: white;
    font-size: 16px;
    align-self: center;
    font-family: Orbitron-Regular;
    font-weight: 700;
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const LogoTitle1 = styled.p`
    color: #FFFFFF;
    font-size: 22px;
    text-align:center;
    display: inline-block;
    font-weight:700;
`
const LogoTitle4 = styled.p`
    color: #FFFFFF;
    font-size: 25px;
    text-align:center;
    display: inline-block;
    font-weight:700;
    &:hover {
        cursor: pointer;
    }
`
const CountDownTxt = styled.p`
    color: #FFFFFF;
    font-size: 48px;
    text-align:center;
    display: inline-block;
    font-weight:700;
    margin-bottom: 50px;
    margin-top: 0;
    width: 100%;
    @media (max-width: 660px) {
        font-size: 36px;
    }
`
const LogoTitle3 = styled.p`
    color: white;
    font-size: 22px;
    height:20px;
    width:50px;
    border-radius:25px;
    align-items:center;
    justify-content:center;
    text-align:center;
    display: inline-block;
    &:hover {
        cursor: pointer;
    }
`
const StackImage = styled.img`
    width: 100%;
    max-width: 400px;
    border-radius: 20px;
`
const StackInfoArea = styled.div`
    width: 100%;
    background-color: rgb(${colors.subMain});
    padding: 4.5em 0 6em 0;
`
const AmountInput = styled.input`
    outline: none;
    border-radius: 10px;
    height: 30px;
    margin: 0 1em;
    width: 100px;
    border: 0;
    padding-left: 10px;
`
const ImageContainer = styled.div`
    width: 80%;
    flex-direction: column;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 2em;
    }
`
const ConnectButton = styled.button`
    border: none;
    background: white;
    color: black;
    font-size:  30px;
    border-radius: 10px;
    font-weight: bold;
    box-shadow: none;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 50px;
    height: 50px;
    &:hover {
        box-shadow: 0 4px 8px 0 rgba(${colors.border});
    }
`
const CalArea = styled.div`
    align-items: center;
    text-align: center;
    max-width: 635px;
    width: 100%;
    padding: 40px;
    margin-top: 1em;
    border-radius: 10px;
    background-color: rgba(${colors.subMain});
    @media (max-width: 660px) {
        padding: 20px;
    }
`
const CalContent = styled.div`
    width: 100%;
    margin: 0 auto;
    border-bottom: 2px solid #9F6FED;
    flex-direction: row;
`
const BuyButton = styled.p`
    color: white;
    font-size: 24px;
    border-radius: 10px;
    width: 50%;
    padding: 10px;
    margin-left: 25%;
    display: flex;
    justify-content: center;
    align-self: center;
    background-color: rgb(${colors.main});
    &:hover {
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const ContainerVideo = styled.video`
  position: relative;
  width: 100%;
  top: 0;
  background-size: cover;
  display: flex;
  @media screen and (max-width: 560px) {
    width: 100%;
    display: none;
  }
`;
const ContainerVideo1 = styled.video`
  position: relative;
  width: 100%;
  top: 0;
  background-size: cover;
  display: none;
  @media screen and (max-width: 560px) {
      width: 100%;
      display: flex;
  }
`;

const theme = createTheme({
    palette: {
        primary: {
            main: '#FFFFFF',
        },
    }
});

interface Props {
    balance: number,
    totalPrice: number,
    onMintChangeButtonClick: (dir: number) => void,
    onMaxButtonClick: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onCallMint: () => void,
    add_chain_with_web3: () => void,
    amount: number,
    maxAmount: number,
    itemPrice: number,
    connect: () => void,
    connected: boolean,
    connectFlag: boolean,
    sombraPrice: number,
    maticPrice: number,
}

let totalprice = 0;

const BannerContent: React.FC<Props> = ({
    balance,
    onMintChangeButtonClick,
    onMaxButtonClick,
    onCallMint,
    amount,
    totalPrice,
    maxAmount,
    itemPrice,
    connect,
    connected,
    connectFlag,
    add_chain_with_web3
}) => {

    const [daysInfo, setDaysInfo] = useState(0);
    const [hoursInfo, setHoursInfo] = useState(0);
    const [minutesInfo, setMinutesInfo] = useState(0);
    const [secondsInfo, setSecondsInfo] = useState(0);
    const [expiredFlag, setExpiredFlag] = useState(false);

    useEffect(() => {
        AOS.init();
        if (countDownData.countdown) {
            var countDownDate = countDownData.countDownDate
            var x = setInterval(function () {

                // Get today's date and time
                var now = new Date().getTime();

                // Find the distance between now and the count down date
                var distance = countDownDate - now;

                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setDaysInfo(days);
                setHoursInfo(hours);
                setMinutesInfo(minutes);
                setSecondsInfo(seconds);

                // Display the result in the element with id="demo"

                // If the count down is finished, write some text
                if (distance < 0) {
                    clearInterval(x);
                    setExpiredFlag(true);
                }
            }, 1000);
        }
    }, []);


    const classes = useStyles();


    // if (!connected) {
    //     return (
    //         <BannerContainer>
    //             <ConnectButton onClick={connect}>Connect</ConnectButton>
    //         </BannerContainer>
    //     )
    // }

    // if (!connectFlag) {
    //     return (
    //         <BannerContainer>
    //             <ConnectButton onClick={add_chain_with_web3}>Connect to polygon</ConnectButton>
    //         </BannerContainer>
    //     )
    // }

    // if (countDownData.countdown) {
    //     return (
    //         <BannerContainer>
    //             {
    //                 expiredFlag ?
    //                     <CountDownTxt>EXPIRED</CountDownTxt> :
    //                     <Container>
    //                         <CountDownTxt><span style={{ color: "#fc5eff" }}>SINS</span> Drop In:</CountDownTxt>
    //                         <CountDownTxt>{daysInfo} <span style={{ fontSize: 25 }}>Days</span> {hoursInfo} <span style={{ fontSize: 25 }}>Hours</span> {minutesInfo} <span style={{ fontSize: 25 }}>Mins</span> {secondsInfo} <span style={{ fontSize: 25 }}>Secs</span></CountDownTxt>
    //                     </Container>
    //             }
    //         </BannerContainer>
    //     )
    // }

    return (
        <BannerContainer>
            <ContainerVideo autoPlay muted loop playsInline>
                <source src={homeVideo} type="video/mp4" />
            </ContainerVideo>
            <ContainerVideo1 autoPlay muted loop playsInline>
                <source src={loopMobile} type="video/mp4" />
            </ContainerVideo1>
            <LogoImg onClick={() => {
                window.open("http://sombra.app/", '_blank')
            }} src={logoImage} alt="" />
            <TopLeft>
                <Title>15TH<br />
                    MARCH<br />
                    2022</Title>
                <SombraLeftImg src={sombraLeftImage} alt="" />
                <TopImageArea>
                    <SombraImg onClick={() => {
                        window.open("https://www.autodesk.com/products/maya/overview?term=1-YEAR&tab=subscription", '_blank')
                    }} src={mayaImage} alt="" />
                    <UnrealImg onClick={() => {
                        window.open("https://www.unrealengine.com/en-US/?utm_source=GoogleSearch&utm_medium=Performance&utm_[…]=&utm_content=July2020_Generic_V1&utm_term=unreal%20engine", '_blank')
                    }} src={unrealImage} alt="" />
                </TopImageArea>
            </TopLeft>
            <MainContent>
                <OutlineTitle data-aos="fade-up">SINS OF SHADOW</OutlineTitle>
                {/* <LineArea data-aos="fade-up">
                    <LineTitle>02</LineTitle>
                    <LineItem />
                    <ActiveLineItem />
                    <LineItem />
                    <LineItem />
                    <LineTitle>/04</LineTitle>
                </LineArea> */}
                <ButtonArea>
                    {/* <WatchButtonItemArea onClick={() => {
                        window.open("https://www.youtube.com/watch?v=IDegm4Hw3eY&t=6s", '_blank')
                    }}>
                        <RightButtonItem style={{ marginLeft: 20 }}>
                            WATCH TRAILER
                        </RightButtonItem>
                    </WatchButtonItemArea> */}
                    {/* <LeftButtonArea data-aos="fade-up">
                        <ButtonItem target='_blank' href={openSeaLink}>
                            OpenSea
                        </ButtonItem>
                        <ButtonItem>
                            Rarity
                        </ButtonItem>
                        <ButtonItem>
                            Roadmape
                        </ButtonItem>
                    </LeftButtonArea> */}
                    <RightButtonArea data-aos="fade-up">
                        <RightButtonItemArea onClick={e => {
                            const mint = document.querySelector('#mintArea');
                            if (mint) {
                                mint.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            }
                        }}>
                            <RightButtonItem>
                                MINT NOW
                            </RightButtonItem>
                        </RightButtonItemArea>
                        <WatchButtonItemArea onClick={() => {
                            window.open("https://www.youtube.com/watch?v=IDegm4Hw3eY&t=6s", '_blank')
                        }}>
                            <RightButtonItem style={{ marginLeft: 20 }}>
                                WATCH TRAILER
                            </RightButtonItem>
                        </WatchButtonItemArea>
                    </RightButtonArea>
                </ButtonArea>
            </MainContent>
            {/* <Container>
                <Grid container spacing={1} className={classes.topArea}>
                    <Grid item md={6} xs={12} className={classes.imgContainer}>
                        <ImageContainer>
                            <StackImage src={homeImage} />
                            <CalContent2>
                                <LogoTitle style={{ padding: "20px" }}>Mint Price</LogoTitle>
                                <LogoTitle1 style={{ padding: "20px" }} >{itemPrice} MATIC</LogoTitle1>
                            </CalContent2>
                        </ImageContainer>
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.calcArea}>
                        <CalArea>
                            <CalContent>
                                <LogoTitle>NFTs REMAINING</LogoTitle>
                                <LogoTitle1>{maxAmount}</LogoTitle1>
                            </CalContent>
                            <CalContent>
                                <LogoTitle>MY MATIC BALANCE</LogoTitle>
                                <LogoTitle1>{balance} MATIC</LogoTitle1>
                            </CalContent>
                            <CalContent>
                                <LogoTitle>AMOUNT</LogoTitle>
                                <Calculator>
                                    <LogoTitle4 onClick={() => { onMintChangeButtonClick(-1) }}>-</LogoTitle4>
                                    <LogoTitle1>{amount}</LogoTitle1>
                                    <AmountInput type="number" value={amount} onChange={onMaxButtonClick} />
                                    <LogoTitle4 onClick={() => { onMintChangeButtonClick(1) }}>+</LogoTitle4>
                                </Calculator>
                                <LogoTitle3>MAX</LogoTitle3>
                            </CalContent>
                            <CalContent1>
                                <LogoTitle>TOTAL PRICE</LogoTitle>
                                <LogoTitle1>{parseFloat(totalPrice.toString()).toFixed(4)} MATIC</LogoTitle1>
                            </CalContent1>
                            {
                                !connected ?
                                    <BuyButton onClick={connect}>Connect</BuyButton> :
                                    !connectFlag ?
                                        <BuyButton onClick={add_chain_with_web3}>Connect to polygon</BuyButton> :
                                        <BuyButton onClick={onCallMint}>Mint</BuyButton>
                            }
                            <LogoTitle5 target='_blank' href={openSeaLink}>View on OpenSea</LogoTitle5>
                        </CalArea>


                    </Grid>
                </Grid>

            </Container> */}

        </BannerContainer>
    )
}

export default BannerContent
