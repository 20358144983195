import React, { useState, useEffect, createRef } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import styled from 'styled-components'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { countDownData, openSeaLink } from '../../sombra_config'
import {
    createTheme,
    makeStyles,
    ThemeProvider,
} from '@material-ui/core/styles';
import { colors } from '../../styles'
import cinematicImage from '../../assets/images/cinematicImage.png'
import sensImage from '../../assets/images/sensImage.png'
import gamingImage from '../../assets/images/gamingImage.png'
import ruleImage from '../../assets/images/ruleImage.png'
import mxiImage from '../../assets/images/mxiImage.png'
import bodegaImage from '../../assets/images/bodegaImage.png'
import womenAvatar from '../../assets/images/womenAvatar.png'
import charactersImage from '../../assets/images/charactersImage.png'
import charactersImage1 from '../../assets/images/charactersImage1.png'
import loadmapImage from '../../assets/images/loadmapImage.png'
import avatar1 from '../../assets/images/avatar1.png'
import avatar2 from '../../assets/images/avatar2.png'
import avatar3 from '../../assets/images/avatar3.png'
import landImage1 from '../../assets/images/landImage1.png'
import vehicleImage from '../../assets/images/vehicleImage.png'
import vehicleImage1 from '../../assets/images/vehicleImage1.png'
import cheatImage from '../../assets/images/cheatImage.png'
import gearImage from '../../assets/images/gearImage.png'
import sombraTokenImg from '../../assets/images/sombraTokenImg.png'
import polygonImage from '../../assets/images/polygonImage.png'
import landImage from '../../assets/images/landImage.png'
import homeImage from '../../assets/images/homeImage.png'
import teamImage from '../../assets/images/teamImage.png'
import bnbImage from '../../assets/images/bnbImage.png'
import Brendan_O from '../../assets/images/Brendan_O.png'
import Chris_Dev_Smith from '../../assets/images/Chris_Dev_Smith.png'
import Demian from '../../assets/images/Demian.png'
import Monika from '../../assets/images/Monika.png'
import Silver from '../../assets/images/Silver.png'
import zackpng from '../../assets/images/zackpng.png'
import Michal from '../../assets/images/Michal.png'
import Jason from '../../assets/images/Jason.png'
import Aron from '../../assets/images/Aron.png'
import Gene from '../../assets/images/Gene.png'
import Kait from '../../assets/images/Kait.png'
import FACEWARE from '../../assets/images/FACEWARE.png'
import MANU from '../../assets/images/MANU.png'
import linkedin from '../../assets/images/linkedin.png'
import gameTopImage from '../../assets/images/gameTopImage.png'
import gameVideo1 from '../../assets/videos/game_demo_2.mp4'
import gameVideo2 from '../../assets/videos/game_demo_1.mp4'
import robVideo from '../../assets/videos/SINS_TEASER.mp4'

// Material ui style
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    container: {
        padding: 0
    },
    textCont: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    imgCont: {
        display: 'flex',
        justifyContent: 'center',
    },
    progress: {
        width: '80%',
        height: 14,
        borderRadius: 7,
    },
    imgContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    topArea: {
        marginBottom: 40,
        [theme.breakpoints.down('md')]: {
            marginBottom: 40,
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        }
    },
    topArea1: {
        marginBottom: 40,
        [theme.breakpoints.down('md')]: {
            marginBottom: 40,
        },
    },
    stackarea: {
        backgroundColor: '#8F4AFF',
        [theme.breakpoints.down('md')]: {
            marginTop: -40,
        },
    },
    calcArea: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center'
        },
    }
}));

// styled-components 
const TopTitle = styled.p`
    font-size: 72px;
    font-weight: 700;
    color: white;
    font-family:BaiJamjuree-Regular;    
    margin: 0;
    margin-top: 30px;
    @media screen and (max-width: 600px) {
        text-align:center;
        margin-left: 0px;
        font-size: 48px;
        margin-top: 50px;
    }
`
const TopTitle1 = styled.p`
    font-size: 72px;
    font-weight: 700;
    color: white;
    font-family:BaiJamjuree-Regular;    
    margin: 0;
    margin-top: 30px;
    margin-left: 100px;
    @media screen and (max-width: 600px) {
        margin-left: 0px;
        font-size: 48px;
        text-align:center;
    }
`
const TopTitle2 = styled.p`
    font-size: 72px;
    font-weight: 700;
    color: white;
    font-family:BaiJamjuree-Regular;    
    margin: 0;
    margin-top: 30px;
    text-align:center;
    display: none;
    @media screen and (max-width: 600px) {
        display: block;
        text-align:center;
        margin: 0px auto;
        font-size: 48px;
        margin-top: 50px;
    }
`
const TopTitle3 = styled.p`
    font-size: 72px;
    font-weight: 700;
    color: white;
    font-family:BaiJamjuree-Regular;    
    margin: 0;
    margin-top: 30px;
    margin-left: 100px;
    display: block;
    @media screen and (max-width: 600px) {
        display: none;
        text-align:center;
        margin-left: 0px;
        font-size: 48px;
        margin-top: 50px;
    }
`
const Description = styled.p`
    font-size: 24px;
    color: white;
    font-family:BaiJamjuree-Regular;    
    margin: 0;
    padding-top: 40px; 
    padding-bottom: 40px; 
    max-width: 920px;
    width: 90%;
    @media screen and (max-width: 1233px) {
        font-size: 18px;
    }
`
const AvatarArea = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content:center;
    @media screen and (max-width: 600px) {
        justify-content:space-around;
    }
`
const Description1 = styled.p`
    font-size: 24px;
    color: white;
    font-family:BaiJamjuree-Regular;    
    margin: 0;
    padding-top: 40px; 
    padding-bottom: 40px; 
    max-width: 524px;
    width: 90%;
    @media screen and (max-width: 560px) {
        font-size: 18px;
        margin: 0 auto;
    }
`
const Description2 = styled.p`
    font-size: 24px;
    color: white;
    font-family:BaiJamjuree-Light;    
    margin: 0;
    padding-top: 40px; 
    padding-bottom: 40px; 
    max-width: 920px;
    width: 90%;
    @media screen and (max-width: 560px) {
        font-size: 18px;
    }
`
const Description3 = styled.p`
    font-size: 24px;
    color: white;
    font-family:BaiJamjuree-Regular;    
    margin: 0;
    padding-top: 40px; 
    padding-bottom: 40px; 
    max-width: 524px;
    width: 90%;
    margin-left: 100px;
    @media screen and (max-width: 560px) {
        margin-left: 0px;
        font-size: 18px;
        margin: 0 auto;
    }
`
const Description4 = styled.p`
    font-size: 24px;
    color: white;
    font-family:BaiJamjuree-Regular;    
    margin: 0;
    padding-top: 40px; 
    padding-bottom: 40px; 
    width: 90%;
    @media screen and (max-width: 560px) {
        padding-top: 20px; 
        font-size: 18px;
    }
`
const Description6 = styled.p`
    font-size: 18px;
    color: white;
    font-family:BaiJamjuree-BoldItalic;    
    margin: 0;
    padding-top: 40px; 
    padding-bottom: 40px; 
    width: 100%;
    @media screen and (max-width: 560px) {
        font-size: 16px;
    }
`
const Description5 = styled.p`
    font-size: 24px;
    color: white;
    font-family:BaiJamjuree-Regular;    
    margin: 0;
    padding-top: 40px; 
    padding-bottom: 40px; 
    width: 100%; 
    @media screen and (max-width: 560px) {
        font-size: 15px;
    }
`
const Description7 = styled.p`
    font-size: 36px;
    color: white;
    font-family:BaiJamjuree-Regular;    
    margin: 0;
    padding-top: 40px; 
    width: 100%; 
    font-weight: 700;
    @media screen and (max-width: 560px) {
        font-size: 20px;
    }
`
const HorizontalLineArea = styled.div`
    width: 100%;
    height: 1px;
    background-color: #FF56F6;
    margin-top: 50px;
    @media screen and (max-width: 560px) {
        margin-top: 0px !important;
    }
`
const HorizontalLineArea1 = styled.div`
    width: 100%;
    height: 1px;
    background-color: #FF56F6;
    margin-top: 50px;
    @media screen and (max-width: 600px) {
        margin-top: 50px !important;
    }
`
// const ConnectButton = styled(OutlineButton)`
//     border: none;
//     background: white;
//     color: black;
//     font-weight: bold;
//     box-shadow: none;
//     &:hover {
//         box-shadow: 0 4px 8px 0 rgba(${colors.border});
//     }
// `
const Container1 = styled.div`
    background-color: rgb(${colors.black});
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items:center;
`
const BannerContainer = styled.div`
    background-color: rgb(${colors.black});
    margin-top: 100px;
    min-height: 120vh;
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    position: relative;
    @media screen and (max-width: 1550px) {
        min-height: 100vh;
    }
    @media screen and (max-width: 959px) {
        margin-top: 50px;
    }
`
const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content:space-between;
    align-items:center;
    height: 100%;
`
const TitleArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 1233px;
    justify-content:center;
    align-items:center;
    height: 173px;
    background-color: #33002B;
    margin-bottom: 30px;
`
const TitleArea1 = styled.div`
    display: flex;
    flex-direction: column;
    width: 1233px;
    justify-content:center;
    align-items:center;
    padding: 40px 0;
    background-color: #33002B;
    margin-bottom: 30px;
    margin-top: 50px;
    border-top: 1px solid #FF56F6;
    border-bottom: 1px solid #FF56F6;
    @media screen and (max-width: 1233px) {
        width: 100%;
        padding: 20px 0;
    }
`
const TopImage = styled.img`
    max-width: 1255px;
    width: 95%;
    display: block;
    @media screen and (max-width: 600px) {
        display: none;
    }
    &:hover {
        transform: scale(1.025);
        box-shadow: 4px 4px 6px 0 #FF56F6;
    }
`
const TopImage2 = styled.img`
    max-width: 1255px;
    width: 100%;
    display: block;
    @media screen and (max-width: 600px) {
        width: 95%;
        margin: 0 auto;
        margin-bottom: 0px;
    }
    &:hover {
        transform: scale(1.025);
        box-shadow: 4px 4px 6px 0 #FF56F6;
    }
`
const TopImage1 = styled.img`
    max-width: 1255px;
    width: 90%;
    display: none;
    @media screen and (max-width: 600px) {
        display: block;
        margin-top: -30px;
    }
    &:hover {
        transform: scale(1.025);
        box-shadow: 4px 4px 6px 0 #FF56F6;
    }
`
const MediumImage = styled.img`
    max-width: 902px;
    width: 95%;
    margin-bottom: 50px;
    margin-top: 30px;
    @media screen and (max-width: 600px) {
        margin-top: -30px;
    }
    &:hover {
        transform: scale(1.1);
        box-shadow: 4px 4px 6px 0 #FF56F6;
    }
`
const MediumImage2 = styled.img`
    width: 100%;
    margin-bottom: 50px;
    margin-top: 50px;
    @media screen and (max-width: 600px) {
        width: 95%;
        width: 95%;
        margin: 50px auto;
        margin-bottom: 0px;
    }
    &:hover {
        transform: scale(1.1);
    }
`
const MediumImage5 = styled.img`
    width: 100%;
    margin-bottom: 50px;
    margin-top: 50px;
    display: block;
    @media screen and (max-width: 600px) {
        display: none;
        width: 95%;
        width: 95%;
        margin: 50px auto;
    }
    &:hover {
        transform: scale(1.1);
        box-shadow: 4px 4px 6px 0 #FF56F6;
    }
`
const MediumImage6 = styled.img`
    width: 100%;
    margin-bottom: 50px;
    margin-top: 50px;
    display: none;
    @media screen and (max-width: 600px) {
        display: flex;
        width: 95%;
        width: 95%;
        margin: 50px auto;
        margin-bottom: 0;
    }
    &:hover {
        transform: scale(1.1);
        box-shadow: 4px 4px 6px 0 #FF56F6;
    }
`
const MediumImage4 = styled.img`
    width: 100%;
    margin-bottom: 50px;
    margin-top: 50px;
    display: none;
    @media screen and (max-width: 560px) {
        display: block;
        width: 70%;
        width: 70%;
        margin: 0px auto;
    }
    &:hover {
        transform: scale(1.1);
        box-shadow: 4px 4px 6px 0 #FF56F6;
    }
`
const MediumImage7 = styled.img`
    width: 118px;
    margin-bottom: 20px;
    @media screen and (max-width: 560px) {
    }
    &:hover {
        transform: scale(1.1);
    }
`
const MediumImage8 = styled.img`
    width: 92px;
    margin-bottom: 46px;
    @media screen and (max-width: 560px) {
    }
    &:hover {
        transform: scale(1.1);
    }
`
const TeamImage = styled.img`
    width: 79.5px;
    margin-right: 10px;
    @media screen and (max-width: 600px) {
        width: 39px;
    }
`
const TeamItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items:center;
    margin: 20px 0px;
    width: 300px;
    @media screen and (max-width: 600px) {
        width: 150px;
    }
`
const DetaiArea = styled.div`
    display: flex;
    flex-direction: column;
`
const TeamLinkedin = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
const LinkedinImage = styled.img`
    width: 25px;
    margin-left: 10px;
    @media screen and (max-width: 600px) {
        width: 20px;
        margin-left: 5px;
    }
    &:hover {
        cursor: pointer;
    }
`
const TeamName = styled.p`
    font-size: 10px;
    color: white;
    font-family:PilatExtended-Black;    
    margin: 0;
    @media screen and (max-width: 680px) {
        font-size: 9px;
    }
`
const TeamPosition = styled.p`
    font-size: 8px;
    color: white;
    font-weight: 900;
    font-family:PilatExtended-Regular;    
    margin: 0;
    @media screen and (max-width: 680px) {
        font-size: 7px;
    }
`
const MediumImage1 = styled.img`
    max-width: 1207px;
    width: 95%;
    margin-bottom: 50px;
    margin-top: 30px;
    @media screen and (max-width: 600px) {
        margin-bottom: 0px;
    }
    &:hover {
        transform: scale(1.1);
        box-shadow: 4px 4px 6px 0 #FF56F6;
    }
`
const MediumImage3 = styled.img`
    max-width: 976px;
    width: 95%;
    margin-bottom: 50px;
    margin-top: 30px;
    @media screen and (max-width: 600px) {
        margin-bottom: -20px;
    }
    &:hover {
        transform: scale(1.1);
        box-shadow: 4px 4px 6px 0 #FF56F6;
    }
`
const LogoArea = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
    width: 90%;
`
const SensImage = styled.img`
    width: 271px;
    align-self: center;
    @media screen and (max-width: 900px) {
        align-self: center;
        margin-right: 0;
    }
    @media screen and (max-width: 780px) {
        width: 150px;
    }
    @media screen and (max-width: 600px) {
        width: 90px;
    }
    &:hover {
        transform: scale(1.1);
    }
`
const ManuImage = styled.img`
    width: 282px;
    align-self: center;
    @media screen and (max-width: 900px) {
        align-self: center;
        margin-right: 0;
    }
    @media screen and (max-width: 780px) {
        width: 160px;
    }
    @media screen and (max-width: 600px) {
        width: 100px;
    }
    &:hover {
        transform: scale(1.1);
    }
`
const FaceWareImage = styled.img`
    width: 190px;
    align-self: center;
    @media screen and (max-width: 900px) {
        align-self: center;
        margin-right: 0;
    }
    @media screen and (max-width: 780px) {
        width: 120px;
    }
    @media screen and (max-width: 600px) {
        width: 80px;
    }
    &:hover {
        transform: scale(1.1);
    }
`
const CountDownTxt = styled.p`
    color: #FFFFFF;
    font-size: 48px;
    text-align:center;
    display: inline-block;
    font-weight:700;
    margin-bottom: 50px;
    margin-top: 0;
    width: 100%;
    @media (max-width: 660px) {
        font-size: 36px;
    }
`
const AprrovingArea = styled.div`
    width: 70%;
    flex-direction: column;
    display: flex;
    margin: 0 auto;
    align-items:center;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 2em;
    }
`
const ThreeImageArea = styled.div`
    width: 100%;
    max-width: 1205px;
    flex-direction: row;
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px;
    @media screen and (max-width: 1205px) {
        flex-direction: column;
        margin-bottom: 0px;
    }
`
const FirstAvatarImage = styled.img` 
    width: 308px;
    height: 411px;
    @media screen and (max-width: 1205px) {
        width: 491px;
        height: 100%;
        margin: 20px auto;
    }
    @media screen and (max-width: 560px) {
        width: 340px;
        height: 100%;
        margin: 20px auto;
    }
`
const SecondAvatarImage = styled.img` 
    width: 491px;
    height: 411px;
    @media screen and (max-width: 1205px) {
        margin: 20px auto;
    }
    @media screen and (max-width: 560px) {
        width: 340px;
        height: 100%;
        margin: 20px auto;
    }
`
const TokenImageArea = styled.div`
    width: 20%;
    flex-direction: column;
    display: flex;
    align-items:center;
    @media screen and (max-width: 560px) {
        width: 340px;
        height: 100%;
        margin: 20px auto;
        display: none;
    }
`
const TokenArea = styled.div`
    width: 90%;
    flex-direction: row;
    display: flex;
`
const TokenLeftArea = styled.div`
    width: 80%;
    flex-direction: column;
    display: flex;
    @media screen and (max-width: 560px) {
        width: 100%;
    }
`
const ProcessingLine = styled.div`
    width: 50%;
    height: 3px;
    background-color: #FF56F6;
    flex-direction: column;
    display: flex;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 2em;
    }
`
const LoadmapTitle = styled.p`
    font-size: 96px;
    color: white;
    font-family:fenwick-outline;;    
    margin: 0;
    text-align:center;
    @media screen and (max-width: 560px) {
        font-size: 64px;
    }
`
const LoadmapTitle1 = styled.p`
    font-size: 96px;
    color: white;
    font-family:fenwick-outline;;    
    margin: 0;
    text-align:center;
    @media screen and (max-width: 680px) {
        font-size: 44px;
    }
`
const ButtonArea = styled.div`
    flex-direction: row;
    display: flex;
    align-items:center;
    justify-content: center;
    @media screen and (max-width: 680px) {
        flex-direction: column;
    }
`
const ButtonItem = styled.div`
    flex-direction: column;
    display: flex;
    align-items:center;
    justify-content: center;
    margin: 0 50px;
    @media screen and (max-width: 680px) {
        margin-bottom: 30px;
    }
`
const BottomButton = styled.div`
    width: 203px;
    height: 57px;
    flex-direction: column;
    display: flex;
    align-items:center;
    background: linear-gradient(120deg, rgba(60, 121, 212,1), rgba(255, 166, 250,0.47) );
    border-width: 2px;
    @media screen and (max-width: 970px) {
        justify-content: center;
        padding-bottom: 2px;
    }
    &:hover {
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const BottomButtonText = styled.div`
    width: 199px;
    height: 53px;
    margin-top: 2px;
    margin-left: 2px;
    background: linear-gradient(120deg, rgba(217, 0, 249,1), rgba(66,0,138,0.47) );
    color: white;
    font-size: 18px;
    font-family: Orbitron-Regular;
    font-weight: 700;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content: center;
`
const ContainerVideo = styled.video`
  position: relative;
  max-width: 1188px;
  width: 90%;
  top: 0;
  background-size: cover;
  display: flex;
  @media screen and (max-width: 560px) {
    width: 90%;
  }
`;

const theme = createTheme({
    palette: {
        primary: {
            main: '#FFFFFF',
        },
    }
});

interface Props {
}

let totalprice = 0;

const BannerContent: React.FC<Props> = ({
}) => {

    const [daysInfo, setDaysInfo] = useState(0);
    const [hoursInfo, setHoursInfo] = useState(0);
    const [minutesInfo, setMinutesInfo] = useState(0);
    const [secondsInfo, setSecondsInfo] = useState(0);
    const [expiredFlag, setExpiredFlag] = useState(false);

    const teamList = [
        { "id": 1, "name": "Brendan Oneil", position: 'Founder / CEO', "image": Brendan_O, linkedin: 'https://www.linkedin.com/in/brendan-o-90555212/' },
        { "id": 2, "name": "Monika Cara", position: 'Head of Community', "image": Monika, linkedin: 'https://www.linkedin.com/in/monika-cara-3399811b3/' },
        { "id": 3, "name": "Michal Finegold", position: 'Partner / Lead Software Developer', "image": Michal, linkedin: 'https://www.linkedin.com/in/michalfinegold' },
        { "id": 4, "name": "Demian Gordon", position: 'Partner / Head of Mocap', "image": Demian, linkedin: 'https://www.linkedin.com/in/demian-gordon-a580552/' },
        { "id": 5, "name": "Zack Derris", position: 'Head of Growth', "image": zackpng, linkedin: 'https://www.linkedin.com/in/zdbtc' },
        { "id": 6, "name": "Chris W", position: 'Lead Blockchain developer', "image": Chris_Dev_Smith, linkedin: '' },
        { "id": 7, "name": "David Jin", position: 'Lead Front end / Web 3 developer', "image": Chris_Dev_Smith, linkedin: '' },
        { "id": 8, "name": "Kait Johnson", position: 'Charity Coordinator', "image": Kait, linkedin: 'https://www.linkedin.com/in/kait-j-2b5a56190' },
        { "id": 9, "name": "Gene Nazarov", position: 'Creative Director', "image": Gene, linkedin: 'https://www.linkedin.com/in/genenazarovnyc' },
        { "id": 10, "name": "Jason Mayo", position: 'Partner / Sales', "image": Jason, linkedin: 'https://www.linkedin.com/in/outnumberedonline' },
        { "id": 11, "name": "Aron Baxter", position: 'Partner / Creative Director', "image": Aron, linkedin: 'https://www.linkedin.com/in/aronbaxter' },
        { "id": 12, "name": "Silver", position: 'Senior Blockchain Supevisor', "image": Silver, linkedin: '' },
    ]

    useEffect(() => {
        AOS.init();
        if (countDownData.countdown) {
            var countDownDate = countDownData.countDownDate
            var x = setInterval(function () {

                // Get today's date and time
                var now = new Date().getTime();

                // Find the distance between now and the count down date
                var distance = countDownDate - now;

                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setDaysInfo(days);
                setHoursInfo(hours);
                setMinutesInfo(minutes);
                setSecondsInfo(seconds);

                // Display the result in the element with id="demo"

                // If the count down is finished, write some text
                if (distance < 0) {
                    clearInterval(x);
                    setExpiredFlag(true);
                }
            }, 1000);
        }
    }, []);


    const classes = useStyles();

    // if (countDownData.countdown) {
    //     return (
    //         <BannerContainer>
    //             {
    //                 expiredFlag ?
    //                     <CountDownTxt>EXPIRED</CountDownTxt> :
    //                     <Container>
    //                         <CountDownTxt><span style={{ color: "#fc5eff" }}>SINS</span> Drop In:</CountDownTxt>
    //                         <CountDownTxt>{daysInfo} <span style={{ fontSize: 25 }}>Days</span> {hoursInfo} <span style={{ fontSize: 25 }}>Hours</span> {minutesInfo} <span style={{ fontSize: 25 }}>Mins</span> {secondsInfo} <span style={{ fontSize: 25 }}>Secs</span></CountDownTxt>
    //                     </Container>
    //             }
    //         </BannerContainer>
    //     )
    // }


    return (
        <Container1>
            <BannerContainer id="roadMap">
                <Container className={classes.container}>
                    <MainContent>
                        <TitleArea1 style={{ marginTop: 0 }}>
                            <LoadmapTitle>ROADMAP</LoadmapTitle>
                        </TitleArea1>
                        <TopImage2 src={loadmapImage} alt="" data-aos="fade-up" />
                        <Description data-aos="fade-up">
                            In 2050, interplanetary travel became possible and with that also interplanetary war. After a series of conflicts in the southern region of the Circinus Galaxy, the Hydrogen Wars erupted into the cosmos wreaking havoc and destruction across space and time. Legends of the Hydrogen Wars and the final battle, The Entropy of Combustion are all that remain of the lost ages.<br /><br />
                            Sins of Shadow is a man-made planet that sits between the Polygon Planets and the Binance Galaxy. Created after the Hydrogen Wars to hold displaced populations whose planets were no longer habitable, over time the planet has evolved into a hub of business and family life, but not without its elements of danger. Only specific areas of the planet are safe for travel whereas others…well let’s just say don’t go there alone. Designed with a large planetary jail at the core of the planet, Sins of Shadow hosts some of the galaxy’s most notorious criminals. It is rumored that ancients, the Hydrogen Wars most brutal and dangerous criminals are still imprisoned there to this day.<br /><br />
                        </Description>
                        <MediumImage style={{ marginBottom: 0 }} src={womenAvatar} alt="" data-aos="fade-up" />
                        <Description style={{ marginTop: 0 }}>
                            In today’s year, 2150, people are living their lives with little recollection of the past. Their daily concerns are mostly the same as yours and mine. They worry about money, their house, cars and slide rides, and of course not getting murdered. Stay safe. It’s a dangerous world out there.<br /><br />
                        </Description>
                        <TopImage src={charactersImage} alt="" data-aos="fade-up" />
                        <TopImage1 src={charactersImage1} alt="" data-aos="fade-up" />
                        <TitleArea1 data-aos="fade-up">
                            <LoadmapTitle1 data-aos="fade-up">
                                PHASE II - BiomaticS 3D
                            </LoadmapTitle1>
                        </TitleArea1>
                        <MediumImage1 src={mxiImage} alt="" data-aos="fade-up" />
                        <Description data-aos="fade-up">
                            Once the Phase 1 mint is 75% complete we will commence Phase 2 which allows owners of 2 or more avatars to upgrade them to 3D for a game ready asset to be used inside our ecosystem and game. The avatars are 1:1 and are high detail 3D custom creations built from the same layers as the illustrations but with so much utility and bespoke craftsmanship. Each avatar has a full IK rig and 52 facial blendshapes for face motion and game action.
                        </Description>
                        <ThreeImageArea>
                            <FirstAvatarImage src={avatar2} alt="" />
                            <SecondAvatarImage src={avatar1} alt="" />
                            <FirstAvatarImage src={avatar3} alt="" />
                        </ThreeImageArea>
                        <TitleArea1 data-aos="fade-up">
                            <LoadmapTitle1 data-aos="fade-up">
                                PHASE III - Gaming
                            </LoadmapTitle1>
                        </TitleArea1>
                        {/* <ContainerVideo autoPlay muted loop playsInline>
                            <source src={gameVideo1} type="video/mp4" />
                        </ContainerVideo> */}
                        <MediumImage1 style={{ margin: 0, marginTop: 20 }} src={gameTopImage} alt="" data-aos="fade-up" />
                        <Description data-aos="fade-up">
                            As our 3D avatars begin to roll out in Phase II, the SOMBRA team will be working hard on multiple installments of Sins of Shadow: Phase III - which will constitute of a community driven Unreal Engine game built in realtime (pun intended) where beyond some fundamentals, the community can help share their wants and desires as we develop. <br /><br />
                        </Description>
                        <MediumImage1 style={{ maxWidth: 822, margin: 0 }} src={gamingImage} alt="" data-aos="fade-up" />
                        <Description data-aos="fade-up">
                            The game will feature 3 main modes: Free-Roam, Battle, Homebase<br /><br />
                            Freeroam - allows the user to walk, run, drive, fly and more around Sins of Shadow landscape and large Unreal Engine map. There are many areas to explore and communities to join.<br /><br />
                            Battle - allows users to enter the Arena to compete against other factions. All battles take place in groups of 8, 10 and 16. An open scale war mode will be implemented in the future.<br /><br />
                            Homebase - allows users and their faction homies to chill at the pad safe from other players attempts to rob them or worse. At home you are safe and so are your guests.
                        </Description>
                        <ContainerVideo style={{ maxWidth: 967 }} autoPlay muted loop playsInline>
                            <source src={gameVideo2} type="video/mp4" />
                        </ContainerVideo>
                        <TitleArea1 data-aos="fade-up">
                            <LoadmapTitle1 data-aos="fade-up">
                                PHASE IV - Cinematic Narrative (VR)
                            </LoadmapTitle1>
                        </TitleArea1>
                        <MediumImage3 src={cinematicImage} alt="" data-aos="fade-up" />
                        <Description data-aos="fade-up">
                            Sins of Shadow is much more than just a game. It is also a cinematic narrative produced each month by the Sombra Team, inside Unreal Engine & starring your favorite avatars! If you own a character in the show you are eligible for special prizes!<br /><br />
                            Each month we drop a cinematic masterpiece that can be experienced as a normal NFT video or as a VR experience where you can immerse yourself in the action and watch the story unfold with you inside it.<br /><br />
                            Users can influence the plot of the show and the wills of the characters based on their SMBR token staking and the amount of NFTs owned in the game. The more you own / stake, the more influence you have on the story. If you get to baller status you might be calling the shots all season long.<br /><br />
                            Built in partnership with Xsens/Movella, Manus, and Faceware tech, Sins of Shadow and Sombra could not bring these amazing characters to life without the help of their amazing groundbreaking motion capture technology and software.
                        </Description>
                        <LogoArea>
                            <ManuImage src={MANU} alt="" data-aos="fade-up" />
                            <SensImage src={sensImage} alt="" data-aos="fade-up" />
                            <FaceWareImage src={FACEWARE} alt="" data-aos="fade-up" />
                        </LogoArea>
                        <TitleArea1 data-aos="fade-up">
                            <LoadmapTitle1 data-aos="fade-up">
                                GAME RULES
                            </LoadmapTitle1>
                        </TitleArea1>
                        <TopImage2 style={{ marginTop: 30 }} src={ruleImage} alt="" data-aos="fade-up" />
                        <Description2 data-aos="fade-up">
                            <span style={{ fontFamily: 'BaiJamjuree-Bold', fontSize: 30 }}>The rules are simple.</span><br /><br />
                            <span style={{ fontFamily: 'BaiJamjuree-Bold' }}>Rule 1</span>: You dont talk about Sins of Shadow<br />
                            <span style={{ fontFamily: 'BaiJamjuree-Bold' }}>Rule 2</span>: You dont talk about Sins of Shadow<br /><br />
                            Just kidding. Tell everyone about this amazing project!<br /><br />
                            <span style={{ fontFamily: 'BaiJamjuree-Bold', fontSize: 30 }}>Rules are as follows:</span><br /><br />
                            1. Be kind to everyone <br /><br />
                            2. Must own 2 or more Phase I 2D avatars to participate in Phase 2/3/4<br /><br />
                            3. To get 10% NFT sale rewards you must stake SMBR and own 2 or more NFTs<br /><br />
                            4. You must use Matic / Polygon and SMBR on Matic chain<br /><br />
                            5. You must not use Hate Speech or anything remotely close to bigotry<br /><br />
                            6. If you are found to be scamming or phishing you will be banned for life and reported to local authorities.<br /><br />
                            7. These are not investments but collectibles. We do not control their aftermarket value. If they happen to gain extreme value on a 2ndary market that is amazing but cannot be guaranted nor is it the primary purpose of the project.<br />
                        </Description2>
                        <TitleArea1 id="collect" data-aos="fade-up">
                            <LoadmapTitle1 data-aos="fade-up">
                                COLLECT
                            </LoadmapTitle1>
                        </TitleArea1>
                    </MainContent>
                    <Grid container spacing={0} className={classes.topArea1}>
                        <TopTitle2 data-aos="fade-up">
                            THE BODEGA
                        </TopTitle2>
                        <Grid item md={6} xs={12} className={classes.calcArea}>
                            <MediumImage2 src={bodegaImage} alt="" data-aos="fade-up" />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.imgContainer}>
                            <TopTitle3 data-aos="fade-up">
                                THE BODEGA
                            </TopTitle3>
                            <Description3 data-aos="fade-up">
                                The Bodega is an NFT digital store front specifically for Sins of Shadow. Users can upload their own 3D creations to the Sins of Shadow team for inspection. If approved we will turn the asset into a game ready NFT that the artist makes money on every time it sells or is traded.<br /><br />
                                Sombra also creates and supplies many different types of NFTs and digital assets for the Sins of Shadow game and experience. Collect all the amazing goods inside our ecosystem.
                            </Description3>
                        </Grid>
                    </Grid>
                    <HorizontalLineArea style={{ marginTop: 0 }} />
                    <Grid container spacing={0} className={classes.topArea}>
                        <Grid item md={6} xs={12} className={classes.imgContainer}>
                            <TopTitle3 style={{ marginLeft: 0 }} data-aos="fade-up">
                                LAND
                            </TopTitle3>
                            <Description1 data-aos="fade-up">
                                Sins of Shadow offers unique land sales all throughout the map.  You can grab a spot on the volcanic surface of the Hydgroden Mines, the bustling fervor of Silver City or the luxury water fronts of Terra Del Mar.<br /><br />
                                It doesn’t stop there. Check out all the other areas for sale soon!
                            </Description1>
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.calcArea}>
                            <MediumImage2 src={landImage} alt="" data-aos="fade-up" />
                        </Grid>
                        <TopTitle2 data-aos="fade-up">
                            LAND
                        </TopTitle2>
                    </Grid>
                    <Grid item md={12} xs={12} className={classes.calcArea}>
                        <TopImage2 src={landImage1} alt="" data-aos="fade-up" />
                    </Grid>
                    <HorizontalLineArea style={{ marginTop: 70 }} />
                    <Grid container spacing={0} className={classes.topArea}>
                        <Grid item md={6} xs={12} className={classes.calcArea}>
                            <MediumImage5 src={vehicleImage} alt="" data-aos="fade-up" />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.imgContainer}>
                            <TopTitle1 data-aos="fade-up">
                                VEHICLES
                            </TopTitle1>
                            <MediumImage6 src={vehicleImage} alt="" data-aos="fade-up" />
                            <Description3 data-aos="fade-up">
                                Getting around is important inside Sins of Shadow’s large scale map. Artists can upload different cars and vehicles to put up for sale and the Sombra team will make them game ready and NFT minted. Sombra also produces its own line of luxury supercars that will be available for purchase and gifted to special users.<br /><br />
                                Get your dream ride soon!
                            </Description3>
                        </Grid>
                    </Grid>
                    <Grid item md={12} xs={12} className={classes.calcArea}>
                        <TopImage2 src={vehicleImage1} alt="" data-aos="fade-up" />
                    </Grid>
                    <HorizontalLineArea1 style={{ marginTop: 70 }} />
                    <Grid container spacing={0} className={classes.topArea1}>
                        <Grid item md={5} xs={12} className={classes.imgContainer}>
                            <TopTitle data-aos="fade-up">
                                GEAR
                            </TopTitle>
                            <Description1 data-aos="fade-up">
                                Buy and sell weapons, clothes, styles, and more inside the Bodega. You can stock up on ammo, power and all sorts of goodies that you’ll need when battling it out against your pals in the Arena. Make sure you’re packing heat and you check the stats of your NFT weapons.<br /><br />
                                Devil is in the details...
                            </Description1>
                        </Grid>
                        <Grid item md={7} xs={12} className={classes.calcArea}>
                            <MediumImage2 src={gearImage} alt="" data-aos="fade-up" />
                        </Grid>
                    </Grid>
                    <HorizontalLineArea style={{ marginTop: 70 }} />
                    <Grid container spacing={0} className={classes.topArea}>
                        <Grid item md={6} xs={12} className={classes.calcArea}>
                            <MediumImage2 src={homeImage} alt="" data-aos="fade-up" />
                        </Grid>
                        <Grid item md={6} xs={12} className={classes.imgContainer}>
                            <TopTitle1 data-aos="fade-up">
                                HOMES
                            </TopTitle1>
                            <Description3 data-aos="fade-up">
                                Buy or build your dream home in Sins of Shadow. With many different landscapes and terrains to choose from, you’re bound to find your perfect pad.<br /><br />
                                Make sure you check out the neighborhood though. We hear some are better than others. Of course luxury comes with a cost so spend wisely...
                            </Description3>
                        </Grid>
                    </Grid>
                    <HorizontalLineArea style={{ marginTop: 70 }} />
                    <MainContent>
                        <TitleArea1 data-aos="fade-up">
                            <LoadmapTitle1 data-aos="fade-up">
                                ROB, STEAL CHEAT
                            </LoadmapTitle1>
                        </TitleArea1>
                        {/* <TopImage2 style={{ marginTop: 30 }} src={cheatImage} alt="" data-aos="fade-up" /> */}
                        <ContainerVideo style={{ maxWidth: 1255 }} autoPlay muted loop playsInline>
                            <source src={robVideo} type="video/mp4" />
                        </ContainerVideo>
                        <Description data-aos="fade-up">
                            Sins of Shadow is a dangerous place so watch out! While in free roam, players can rob, beat and kill other players in a true digital WestWorld fashion. Players also must carry money if they wish to enter into the Arena for battle or betting and that money can be stolen while in free roam. Careful though, factions roam together and the Sombra Rangers are always around watching your moves. They have no mercy and will not hesitate to take you down. If you get taken down by a Ranger or opposing team, you risk losing all your pocket change as well.<br /><br />
                            Bodyguards and mercanary companies can be hired to protect you and your crew if needed...for a cost of course.
                        </Description>
                        <TitleArea1 data-aos="fade-up" id="team">
                            <LoadmapTitle1 data-aos="fade-up">
                                TEAM
                            </LoadmapTitle1>
                        </TitleArea1>
                        <Description data-aos="fade-up">
                            Sins of Shadow is owned and operated by the Sombra Network. Our top level leadership is comprised of award winning VFX veterans, emerging tech experts and prosperous corporate business owners.<br /><br />
                            We are a doxxed project and on Linkedin as well as attached to many other top level companies. We are always available to discuss any questions or concerns. Please find us in Discord or Telegram.
                        </Description>
                        <AvatarArea>
                            {
                                teamList.map((item, index) => {
                                    return (
                                        <TeamItem key={index}>
                                            <TeamImage src={item.image} alt="" />
                                            <DetaiArea>
                                                <TeamLinkedin>
                                                    <TeamName>{item.name}</TeamName>
                                                    {item.linkedin !== "" && <LinkedinImage onClick={() => {
                                                        window.open(item.linkedin, '_blank')
                                                    }} src={linkedin} alt="" />}
                                                </TeamLinkedin>
                                                <TeamPosition>{item.position}</TeamPosition>
                                            </DetaiArea>
                                        </TeamItem>
                                    )
                                })
                            }
                        </AvatarArea>
                        <TitleArea1 data-aos="fade-up" id="smbrToken">
                            <LoadmapTitle1 data-aos="fade-up">
                                SMBR TOKEN
                            </LoadmapTitle1>
                        </TitleArea1>
                        <Description4 data-aos="fade-up">
                            <span style={{ color: '#FF00F5' }}>SMBR</span> Token is the utility tokens that powers the SINS of Shadow NFT ecosystem and our NFT marketplace. Alongside native tokens such as BNB and Matic, SMBR is at the heart of our community and growth. <span style={{ color: '#FF00F5' }}>SMBR</span> is required for participation in <span style={{ color: '#FF00F5' }}>Sins of Shadow</span>.
                        </Description4>
                        <Description4 style={{ margin: 0, padding: 0 }} data-aos="fade-up">
                            SMBR token is traded Polygon Network and Binance Smart Chain
                        </Description4>
                        <TokenArea>
                            <TokenLeftArea>
                                <Description7>HOW TO GET MATIC SMBR</Description7>
                                <Description5 data-aos="fade-up">
                                    <MediumImage4 style={{ marginTop: 10, marginBottom: 10 }} src={polygonImage} alt="" data-aos="fade-up" />
                                    SMBR token is traded Polygon Network and Binance Smart Chain<br /><br />
                                    *SINS OF SHADOW RUNS ON <span style={{ color: '#FF00F5' }}>POLYGON/MATIC</span>*<br /><br />
                                    1. Download Metamask or Trustwallet (web 3 wallets)<br />
                                    2. Connect your web 3 wallet to Sombra Swap/Pancakeswap/Quickswap<br />
                                    3. Set slippage to 7% <span style={{ fontSize: 17 }}>(this is found in the little gear icon and allows for the SMBR 6% tax)4. 5</span><br />
                                    4. Enter the amount of BNB you want to swap<br />
                                    5. Confirm Swap<br />
                                    6. Confirm Web 3 transaction<br />
                                    7. SMBR is now in your wallet<br /><br />
                                    Please note you must have SMBR added to your web 3 wallet using the contract address(es): <br /><br />
                                    <span style={{ color: '#FFD600' }}>BSC</span> - 0x8ad8e9b85787ddd0d31b32ecf655e93bfc0747ef (SOMBRASWAP)<br />
                                    <span style={{ color: '#FF00F5' }}>MATIC</span> - 0x16B3E050e9e2f0Ac4f1BEA1b3E4fdc43d7f062Dd (QUICKSWAP)<br />
                                </Description5>
                                <MediumImage4 style={{ marginTop: 10, marginBottom: 10 }} src={sombraTokenImg} alt="" data-aos="fade-up" />
                                <Description6>
                                    Sombra Network does not control the price of SMBR and cannot guarantee any financial profits from purchasing SMBR. Please purchase SMBR at your own risk and understand how to use web 3 applications prior to making any purchases. Please always check URLs and never respond to emails asking for web 3 connection. Also NEVER give our passwords or seed phrases. NEVER EVER EVER. Never give our your seed or passphrase.
                                </Description6>
                            </TokenLeftArea>
                            <TokenImageArea>
                                <MediumImage2 style={{ marginTop: 10, marginBottom: 10 }} src={polygonImage} alt="" data-aos="fade-up" />
                                <MediumImage2 style={{ marginTop: 10, marginBottom: 10 }} src={sombraTokenImg} alt="" data-aos="fade-up" />
                            </TokenImageArea>
                        </TokenArea>
                        <ButtonArea>
                            <ButtonItem>
                                <MediumImage7 src={polygonImage} alt="" />
                                <BottomButton onClick={() => {
                                    window.open("https://quickswap.exchange/#/swap?outputCurrency=0x16b3e050e9e2f0ac4f1bea1b3e4fdc43d7f062dd", '_blank')
                                }}>
                                    <BottomButtonText>BUY SMBR</BottomButtonText>
                                </BottomButton>
                            </ButtonItem>
                            <ButtonItem>
                                <MediumImage8 src={bnbImage} alt="" />
                                <BottomButton onClick={() => {
                                    window.open("https://www.sombraswap.com", '_blank')
                                }}>
                                    <BottomButtonText>BUY SMBR</BottomButtonText>
                                </BottomButton>
                            </ButtonItem>
                        </ButtonArea>
                    </MainContent>
                </Container>
            </BannerContainer>
        </Container1>
    )
}

export default BannerContent
