import React, { useState, useEffect, createRef } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import styled from 'styled-components'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { countDownData, openSeaLink } from '../../sombra_config'
import {
    createTheme,
    makeStyles,
    ThemeProvider,
} from '@material-ui/core/styles';
import { colors } from '../../styles'
import { useHistory } from 'react-router-dom'
import logoImage from '../../assets/images/logo.png'
import sombraImage from '../../assets/images/sombraImage.png'
import sombraLeftImage from '../../assets/images/sombraLeftImage.png'
import avatarsImage from '../../assets/images/avatarsImage.png'
import mediumImage from '../../assets/images/mediumImage.gif'
import randomAvatar from '../../assets/images/randomAvatar.png'
import homeImage from '../../assets/images/homeImage.gif'
import mediumVideo from '../../assets/videos/mediumVideo.mp4'

// Material ui style
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    textCont: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    imgCont: {
        display: 'flex',
        justifyContent: 'center',
    },
    progress: {
        width: '80%',
        height: 14,
        borderRadius: 7,
    },
    imgContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    topArea: {
        [theme.breakpoints.down('md')]: {
            marginBottom: 40,
        },
    },
    stackarea: {
        backgroundColor: '#8F4AFF',
        [theme.breakpoints.down('md')]: {
            marginTop: -40,
        },
    },
    calcArea: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center',
        },
    }
}));

interface ButtonProps {
    status: number;
}

// styled-components 
const LogoImg = styled.img`
    width: 42px;
    height: 24px;
    position: absolute;
    top: 41px;
    left: 50px;
`
const SombraImg = styled.img`
    width: 60px;
    height: 60px;
`
const SombraLeftImg = styled.img`
    width: 3.5px;
    height: 72px;
    margin-right: 20px;
    margin-left: 20px;
`
const Title = styled.h1`
    font-size: 25px;
    color: white;
    font-family: Gilroy-Light;
    font-weight: 100;
    text-align: right;
    margin: 0;
`
const TopLeft = styled.div`
    position: absolute;
    right: 22px;
    top: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
`
const OutlineTitle = styled.p`
    font-size: 72px;
    font-weight: 400;
    color: white;
    font-family: fenwick-outline;
    margin: 0;
    padding-top: 40px; 
    letter-spacing: 0.2em;
    text-shadow: 4px 4px rgba(${colors.shadow});
`
const TopTitle = styled.p`
    font-size: 72px;
    color: white;
    font-family:fenwick-outline;;    
    margin: 0;
    padding-top: 40px; 
    padding-bottom: 40px; 
    margin-bottom: 50px;
    text-align:center;
    @media (max-width: 980px) {
        font-size: 44px;
    }
`
const Description = styled.p`
    font-size: 24px;
    color: white;
    font-family:BaiJamjuree-Regular;    
    margin: 0;
    padding-top: 40px; 
    padding-bottom: 40px; 
    max-width: 920px;
    width: 90%;
    @media (max-width: 980px) {
        font-size: 18px;
    }
`
const HorizontalLineArea = styled.div`
    width: 90%;
    max-width: 1250px;;
    height: 1px;
    background-color: #FF56F6;
    margin-top: 30px;
    @media (max-width: 980px) {
        display: none;
    }
`
// const ConnectButton = styled(OutlineButton)`
//     border: none;
//     background: white;
//     color: black;
//     font-weight: bold;
//     box-shadow: none;
//     &:hover {
//         box-shadow: 0 4px 8px 0 rgba(${colors.border});
//     }
// `
const Container1 = styled.div`
    background-color: rgb(${colors.black});
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items:center;
`
const BannerContainer = styled.div`
    background-color: rgb(${colors.black});
    background-image: url(/images/background1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 120vh;
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    position: relative;
    @media screen and (max-width: 1550px) {
        min-height: 100vh;
    }
`
const LineArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: auto 0;
    width: 100%;
    flex-wrap: wrap;
`
const LineTitle = styled.p`
    color: white;
    font-size: 24px;
    text-align:center;
    font-family: Gilroy-BoldItalic;
    margin:0;
    @media (max-width: 660px) {
        font-size: 16px;
    }
`
const LineItem = styled.div`
    width: 2px;
    height: 70px;
    background-color: white;
    margin: 5px 12px;
`
const ActiveLineItem = styled.div`
    width: 4px;
    height: 70px;
    background-color: #D900F9;
    margin: 5px 0;
    margin: 5px 11px;
`
const ButtonArea = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const LeftButtonArea = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 100%;
    padding-bottom: 50px;
    @media (max-width: 1550px) {
        padding-bottom: 20px;
    }
`
const RightButtonArea = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding-bottom: 100px;
    @media (max-width: 1550px) {
        padding-bottom: 70px;
    }
`
const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content:space-between;
    align-items:center;
    height: 100%;
`
const TopImage = styled.img`
    width: 1301px;
    margin-top: -50px;
    @media (max-width: 1301px) {
        width: 95%;
    }
    &:hover {
        transform: scale(1.025);
        -ms-transform: scale(1.025);
        box-shadow: 4px 4px 6px 0 #FF56F6;
    }
`
const MediumImage = styled.img`
    width: 431px;
    margin-bottom: 100px;
    margin-top: 50px;
    border-radius: 20px;
    @media (max-width: 500px) {
        width: 70%;
        margin-bottom: 00px;
        margin-top: 0px;
    }
`
const CalContent2 = styled.div`
    width: 100%;
    max-width: 400px;
    // border-bottom: 2px solid #9F6FED;
    flex-direction: row;
    justify-content:space-between;
    display: flex;
    align-items:center;
    background-color: #8F4AFF;
    margin-top: 10px;
    border-radius: 100%;
    font-weight: bold;
    
`
const Calculator = styled.div`
    // width: 100px;
    // margin: 0 auto;
    flex-direction: row;
    justify-content:space-between;
    align-items:center;
    display: flex;
    margin-right: -27px;
`
const CalContent1 = styled.div`
    width: 100%;
    margin: 0 auto;
    flex-direction: row;
    justify-content:space-between;
    display: flex;
    align-items:center;
`
const ButtonItem = styled.p`
    color: white;
    font-size: 18px;
    align-self: center;
    font-family: Orbitron-Regular;
    font-weight: 700;
    margin: 0 30px;
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const RightButtonItem = styled.p`
    color: white;
    font-size: 16px;
    align-self: center;
    font-family: Orbitron-Regular;
    font-weight: 700;
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const RightButtonItemArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    width: 255px;
    height: 71px;
    background-image: url(/images/mintButtonImage.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-right: 50px;
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const WatchButtonItemArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    width: 255px;
    height: 52px;
    background-image: url(/images/watchButtonImage.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const LogoTitle5 = styled.a`
    color: white;
    font-size: 14px;
    text-align:center;
    display: inline-block;
    &:hover {
        cursor: pointer;
    }
`
const LogoTitle = styled.p`
    color: white;
    font-size: 24px;
    align-self: center;
    font-family:BaiJamjuree-Regular; 
    font-weight: 700;
    @media (max-width: 660px) {
        font-size: 22px;
    }
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const LogoTitle1 = styled.p`
    color: #000;
    font-size: 18px;
    text-align:center;
    display: inline-block;
    background-color: #C4C4C4;
    width: 110px;
    height: 30px;
    border-radius: 15px;
    padding-top: 3px;
    font-family: Orbitron-Regular;
    @media (max-width: 660px) {
        font-size: 16px;
    }
`
const LogoTitle4 = styled.p`
    color: #FFFFFF;
    font-size: 25px;
    text-align:center;
    display: inline-block;
    font-weight:700;
    padding: 0px;
    margin: 0px;
    &:hover {
        cursor: pointer;
    }
`
const CountDownTxt = styled.p`
    color: #FFFFFF;
    font-size: 48px;
    text-align:center;
    display: inline-block;
    font-weight:700;
    margin-bottom: 50px;
    margin-top: 0;
    width: 100%;
    @media (max-width: 660px) {
        font-size: 22px;
    }
`
const LogoTitle3 = styled.p`
    color: white;
    font-size: 22px;
    height:20px;
    width:50px;
    border-radius:25px;
    align-items:center;
    justify-content:center;
    text-align:center;
    display: inline-block;
    &:hover {
        cursor: pointer;
    }
`
const StackImage = styled.img`
    width: 100%;
    max-width: 629px;
`
const StackInfoArea = styled.div`
    width: 100%;
    background-color: rgb(${colors.subMain});
    padding: 4.5em 0 6em 0;
`
const AmountInput = styled.input`
    outline: none;
    margin: 0 1em;
    background-color: #C4C4C4;
    width: 110px;
    height: 30px;
    border-radius: 15px;
    border: 0;
    padding-left: 10px;
    text-align:center;
    margin: 10px;
`
const ImageContainer = styled.div`
    width: 80%;
    flex-direction: column;
    display: flex;
    align-items:center;
    margin: 0 auto;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
    }
`
const AprrovingArea = styled.div`
    width: 70%;
    flex-direction: column;
    display: flex;
    margin: 0 auto;
    align-items:center;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 0em;
    }
`
const BottomButtonArea = styled.div`
    width: 100%;
    flex-direction: row;
    display: flex;
    margin: 0 auto;
    align-items:center;
    justify-content: space-between;
    margin-top: 70px;
    @media screen and (max-width: 960px) {
        width: 100%;
        margin-top: 30px;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 0em;
    }
`
const BottomButton = styled.div`
    width: 297px;
    height: 60px;
    flex-direction: column;
    display: flex;
    align-items:center;
    background: linear-gradient(120deg, rgba(60, 121, 212,1), rgba(255, 166, 250,0.47) );
    border-radius: 30px;
    border-width: 2px;
    padding:2px;
    @media screen and (max-width: 959px) {
        width: 80%;
        justify-content: center;
        margin-bottom: 20px;
    }
    &:hover {
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const BottomButtonText = styled.div`
    width: 293px;
    height: 56px;
    background: linear-gradient(120deg, rgba(217, 0, 249,1), rgba(66,0,138,0.47) );
    color: white;
    font-size: 18px;
    font-family: Orbitron-Regular;
    font-weight: 700;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content: center;
    border-radius: 28px;
    @media screen and (max-width: 959px) {
        width: 100%;
    }
`
const ProcessingArea = styled.div`
    width: 100%;
    flex-direction: column;
    display: flex;
    margin: 0 auto;
    align-items: flex-start;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 2em;
    }
`
const ProcessingBar = styled.div`
    width: 100%;
    height: 1px;
    background-color: #FF56F6;
    flex-direction: column;
    display: flex;
    align-items:center;
    margin: 0 auto;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 2em;
    }
`
const ProcessingLine = styled.div`
    width: 50%;
    height: 3px;
    background-color: #FF56F6;
    flex-direction: column;
    display: flex;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 2em;
    }
`
const ProcessingLine1 = styled.div`
    width: 100%;
    height: 3px;
    background-color: #FF56F6;
    flex-direction: column;
    display: flex;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 2em;
    }
`
const ConnectButton = styled.button`
    border: none;
    background: white;
    color: black;
    font-size:  30px;
    border-radius: 10px;
    font-weight: bold;
    box-shadow: none;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 50px;
    height: 50px;
    &:hover {
        box-shadow: 0 4px 8px 0 rgba(${colors.border});
    }
`
const CalArea = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    text-align: center;
    max-width: 450px;
    width: 100%;
    padding: 40px;
    margin-top: -2em;
    border-radius: 10px;
    @media (max-width: 660px) {
        padding: 20px;
    }
`
const CalContent = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #9F6FED;
    flex-direction: row;
    align-items:center;
    padding: 20px 0;
`
const BuyButton = styled.button<ButtonProps>`
    color: white;
    font-size: 14px;
    border-radius: 22.5px;
    width: 50%;
    padding: 12px;
    font-weight: 700;
    margin-top: 50px;
    font-family: Orbitron-Regular;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 200px;
    height: 45px;
    background: linear-gradient(${props => props.status === 1 ? '0deg, rgba(77, 71, 99,1), rgba(77,71,99,0.47)' : '0deg, rgba(255, 0, 214,1), rgba(255,0,175,0.47)'});
    // background: linear-gradient(0deg, rgba(255, 0, 214,1), rgba(255,0,175,0.47) );
    &:hover {
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const ContainerVideo = styled.video`
    position: relative;
    width: 431px;
    top: 0;
    background-size: cover;
    margin-top: 50px;
    border-radius: 20px;
    @media (max-width: 450px) {
        width: 80%;
    }
`;

const theme = createTheme({
    palette: {
        primary: {
            main: '#FFFFFF',
        },
    }
});

interface Props {
    balance: number,
    totalPrice: number,
    onMintChangeButtonClick: (dir: number) => void,
    onMaxButtonClick: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onCallMint: () => void,
    add_chain_with_web3: () => void,
    amount: number,
    maxAmount: number,
    itemPrice: number,
    mintLoading: number,
    connect: () => void,
    connected: boolean,
    connectFlag: boolean,
}

let totalprice = 0;

const BannerContent: React.FC<Props> = ({
    balance,
    onMintChangeButtonClick,
    onMaxButtonClick,
    onCallMint,
    amount,
    totalPrice,
    maxAmount,
    itemPrice,
    connect,
    connected,
    connectFlag,
    add_chain_with_web3,
    mintLoading
}) => {
    let history = useHistory()

    const [daysInfo, setDaysInfo] = useState(0);
    const [hoursInfo, setHoursInfo] = useState(0);
    const [minutesInfo, setMinutesInfo] = useState(0);
    const [secondsInfo, setSecondsInfo] = useState(0);
    const [expiredFlag, setExpiredFlag] = useState(false);
    const [loadingNum, setLoadingNum] = useState(0);

    useEffect(() => {
        console.log("mintLoading", mintLoading)
        setLoadingNum(mintLoading)
    }, [mintLoading])


    useEffect(() => {
        AOS.init();
        if (countDownData.countdown) {
            var countDownDate = countDownData.countDownDate
            var x = setInterval(function () {

                // Get today's date and time
                var now = new Date().getTime();

                // Find the distance between now and the count down date
                var distance = countDownDate - now;

                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setDaysInfo(days);
                setHoursInfo(hours);
                setMinutesInfo(minutes);
                setSecondsInfo(seconds);

                // Display the result in the element with id="demo"

                // If the count down is finished, write some text
                if (distance < 0) {
                    clearInterval(x);
                    setExpiredFlag(true);
                }
            }, 1000);
        }
    }, []);


    const classes = useStyles();

    const warning = () => {
        alert("Not quite ready yet")
    }

    // if (countDownData.countdown) {
    //     return (
    //         <BannerContainer>
    //             {
    //                 expiredFlag ?
    //                     <CountDownTxt>EXPIRED</CountDownTxt> :
    //                     <Container>
    //                         <CountDownTxt><span style={{ color: "#fc5eff" }}>SINS</span> Drop In:</CountDownTxt>
    //                         <CountDownTxt>{daysInfo} <span style={{ fontSize: 25 }}>Days</span> {hoursInfo} <span style={{ fontSize: 25 }}>Hours</span> {minutesInfo} <span style={{ fontSize: 25 }}>Mins</span> {secondsInfo} <span style={{ fontSize: 25 }}>Secs</span></CountDownTxt>
    //                     </Container>
    //             }
    //         </BannerContainer>
    //     )
    // }

    const navigateTo = (targetPath: string) => {
        history.push(`/${targetPath}`)
    }


    return (
        <Container1>
            <TopTitle data-aos="fade-up">
                PHASE I - THE BIOMATICS 2D
            </TopTitle>
            <BannerContainer>
                <MainContent>
                    <TopImage src={avatarsImage} alt="" data-aos="fade-up" />
                    <Description data-aos="fade-up">
                        Welcome to Sins of Shadow - a multi-layer NFT utility ecosystem that provides open source, bespoke metaverse experiences and immersive storytelling through a decentralized network of top level VFX artists, and NFT/Gaming enthusiasts.<br /><br />
                        Sins of Shadow is the creative IP of Sombra Network and has been developed to bring communities of gamers, comic, film, anime, and of course NFT lovers together to experience a next level, digital social experience that truly takes us one step closer to the digital revolution.
                    </Description>
                    <HorizontalLineArea />
                    <MediumImage src={mediumImage} alt="" />
                    {/* <ContainerVideo data-aos="fade-up" autoPlay muted loop playsInline>
                        <source src={mediumVideo} type="video/mp4" />
                    </ContainerVideo> */}
                    <Description data-aos="fade-up" data-aos-duration="2000">
                        Phase 1 of the SINS of Shadow project launches with 7,777 custom illustrated 2D avatars, genetically generated from a comprehensive custom code set and a wide array of unique traits that include many races and gender identities. <br /><br />
                        Each avatar is totally random at mint and consists of over 8 different characteristics and over 300 unique layers and traits. Avatar rarity can be checked in our rarity tool as well as on OpenSea. <br /><br />
                        Phase I avatars each have a 3D twin that members can upgrade to in the future. This is Phase II and begins the motion capture and Unreal Engine game journey. Members must own 2 or more Sins of Shadow NFTs to qualify for Phase II
                    </Description>
                </MainContent>
                <Container>
                    <Grid container spacing={1} className={classes.topArea}>
                        <Grid item md={12} xs={12} className={classes.imgContainer}>
                            <ImageContainer data-aos="fade-up" id="mintArea">
                                <StackImage src={randomAvatar} />
                            </ImageContainer>
                        </Grid>
                    </Grid>
                </Container>
                <Container>
                    <Grid item md={12} xs={12} className={classes.calcArea}>
                        {
                            countDownData.countdown ?
                                expiredFlag ?
                                    <CountDownTxt>EXPIRED</CountDownTxt> :
                                    <Container>
                                        <CountDownTxt><span style={{ color: "#fc5eff" }}>SINS</span> Drop In:</CountDownTxt>
                                        <CountDownTxt>{daysInfo} <span style={{ fontSize: 16 }}>Days</span> {hoursInfo} <span style={{ fontSize: 16 }}>Hours</span> {minutesInfo} <span style={{ fontSize: 16 }}>Mins</span> {secondsInfo} <span style={{ fontSize: 16 }}>Secs</span></CountDownTxt>
                                    </Container> :
                                <CalArea data-aos="fade-up">
                                    <CalContent>
                                        <LogoTitle>NFTS REMAINING:</LogoTitle>
                                        <LogoTitle1>{maxAmount}</LogoTitle1>
                                    </CalContent>
                                    <CalContent>
                                        <LogoTitle>MATIC BALANCE:</LogoTitle>
                                        <LogoTitle1>{balance}</LogoTitle1>
                                    </CalContent>
                                    <CalContent>
                                        <LogoTitle>MINT PRICE:</LogoTitle>
                                        <LogoTitle1>{itemPrice}</LogoTitle1>
                                    </CalContent>
                                    <CalContent>
                                        <LogoTitle>MINT AMOUNT:</LogoTitle>
                                        <Calculator>
                                            <LogoTitle4 onClick={() => { onMintChangeButtonClick(-1) }}>-</LogoTitle4>
                                            <AmountInput type="number" value={amount} onChange={onMaxButtonClick} />
                                            <LogoTitle4 onClick={() => { onMintChangeButtonClick(1) }}>+</LogoTitle4>
                                        </Calculator>
                                    </CalContent>
                                    {!connected ?
                                        <BuyButton status={loadingNum} onClick={connect}>Connect Wallet</BuyButton> :
                                        !connectFlag ?
                                            <BuyButton status={loadingNum} onClick={add_chain_with_web3}>Connect to polygon</BuyButton> :
                                            <BuyButton status={loadingNum} disabled={loadingNum === 1} onClick={onCallMint}>Mint</BuyButton>}
                                </CalArea>
                        }
                        {/* <BuyButton status={loadingNum} onClick={warning}>Connect Wallet</BuyButton> : */}
                        {/* {
                                !connected ?
                                    <BuyButton status={loadingNum} onClick={connect}>Connect Wallet</BuyButton> :
                                    !connectFlag ?
                                        <BuyButton status={loadingNum} onClick={add_chain_with_web3}>Connect to polygon</BuyButton> :
                                        <BuyButton status={loadingNum} disabled={loadingNum === 1} onClick={onCallMint}>Mint</BuyButton>
                            } */}
                    </Grid>
                </Container>
                {
                    loadingNum === 0 ? null :
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Description style={{ textAlign: 'center' }}>
                                {loadingNum === 1 ? "Approving transaction..." : "Item minted successfully."}
                            </Description>
                            <ProcessingArea>
                                {loadingNum === 1 ? <ProcessingLine /> : <ProcessingLine1 />}
                                <ProcessingBar />
                            </ProcessingArea>
                        </div>
                }
            </BannerContainer>
            <AprrovingArea data-aos="fade-up">
                <BottomButtonArea>
                    {/* <BottomButton> */}
                    <BottomButton onClick={() => {
                        window.open(openSeaLink, '_blank')
                    }}>
                        <BottomButtonText>OPENSEA</BottomButtonText>
                    </BottomButton>
                    <BottomButton onClick={warning}>
                        <BottomButtonText>RARITY</BottomButtonText>
                        {/* <BottomButtonText onClick={() => navigateTo("rarity")}>RARITY</BottomButtonText> */}
                    </BottomButton>
                    <BottomButton>
                        <BottomButtonText onClick={e => {
                            const mint = document.querySelector('#roadMap');
                            if (mint) {
                                mint.scrollIntoView({ behavior: 'smooth' });
                            }
                        }}>ROADMAP</BottomButtonText>
                    </BottomButton>
                </BottomButtonArea>
            </AprrovingArea>
        </Container1 >
    )
}

export default BannerContent
