import React, { useState, useEffect, createRef } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import styled from 'styled-components'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { countDownData, openSeaLink } from '../../sombra_config'
import {
    createTheme,
    makeStyles,
    ThemeProvider,
} from '@material-ui/core/styles';
import { colors } from '../../styles'
import { useHistory } from 'react-router-dom'
import rewardImage from '../../assets/images/rewardImage.png'

// Material ui style
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    textCont: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    imgCont: {
        display: 'flex',
        justifyContent: 'center',
    },
    progress: {
        width: '80%',
        height: 14,
        borderRadius: 7,
    },
    imgContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    topArea: {
        [theme.breakpoints.down('md')]: {
            marginBottom: 40,
        },
    },
    stackarea: {
        backgroundColor: '#8F4AFF',
        [theme.breakpoints.down('md')]: {
            marginTop: -40,
        },
    },
    calcArea: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center',
        },
    }
}));

interface ButtonProps {
    status: number;
}

// styled-components 
const LogoImg = styled.img`
    width: 42px;
    height: 24px;
    position: absolute;
    top: 41px;
    left: 50px;
`
const SombraImg = styled.img`
    width: 60px;
    height: 60px;
`
const SombraLeftImg = styled.img`
    width: 3.5px;
    height: 72px;
    margin-right: 20px;
    margin-left: 20px;
`
const Title = styled.h1`
    font-size: 25px;
    color: white;
    font-family: Gilroy-Light;
    font-weight: 100;
    text-align: right;
    margin: 0;
`
const TopLeft = styled.div`
    position: absolute;
    right: 22px;
    top: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
`
const OutlineTitle = styled.p`
    font-size: 72px;
    font-weight: 400;
    color: white;
    font-family: fenwick-outline;
    margin: 0;
    padding-top: 40px; 
    letter-spacing: 0.2em;
    text-shadow: 4px 4px rgba(${colors.shadow});
`
const TopTitle = styled.p`
    font-size: 72px;
    color: white;
    font-family:fenwick-outline;;    
    margin: 0;
    padding-top: 40px; 
    padding-bottom: 40px; 
    margin-bottom: 50px;
`
const Description = styled.p`
    font-size: 24px;
    color: white;
    font-family:BaiJamjuree-Regular;    
    margin: 0;
    padding-top: 40px; 
    padding-bottom: 40px; 
    max-width: 920px;
    width: 90%;
    @media screen and (max-width: 560px) {
        font-size: 18px;
    }
`
const HorizontalLineArea = styled.div`
    width: 80%;
    height: 1px;
    background-color: #FF56F6;
    margin-top: 30px;
`
// const ConnectButton = styled(OutlineButton)`
//     border: none;
//     background: white;
//     color: black;
//     font-weight: bold;
//     box-shadow: none;
//     &:hover {
//         box-shadow: 0 4px 8px 0 rgba(${colors.border});
//     }
// `
const Container1 = styled.div`
    background-color: rgb(${colors.black});
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    padding-top: 50px;
`
const BannerContainer = styled.div`
    background-color: rgb(${colors.black});
    background-image: url(/images/background1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 120vh;
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    position: relative;
    @media screen and (max-width: 1550px) {
        min-height: 100vh;
    }
`
const LineArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: auto 0;
    width: 100%;
    flex-wrap: wrap;
`
const LineTitle = styled.p`
    color: white;
    font-size: 24px;
    text-align:center;
    font-family: Gilroy-BoldItalic;
    margin:0;
    @media (max-width: 660px) {
        font-size: 16px;
    }
`
const LineItem = styled.div`
    width: 2px;
    height: 70px;
    background-color: white;
    margin: 5px 12px;
`
const ActiveLineItem = styled.div`
    width: 4px;
    height: 70px;
    background-color: #D900F9;
    margin: 5px 0;
    margin: 5px 11px;
`
const ButtonArea = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const LeftButtonArea = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 100%;
    padding-bottom: 50px;
    @media (max-width: 1550px) {
        padding-bottom: 20px;
    }
`
const RightButtonArea = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding-bottom: 100px;
    @media (max-width: 1550px) {
        padding-bottom: 70px;
    }
`
const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content:space-between;
    align-items:center;
    height: 100%;
`
const TopImage = styled.img`
    width: 956px;
    @media screen and (max-width: 956px) {
        width: 90%;
    }
    &:hover {
        transform: scale(1.025);
        -ms-transform: scale(1.025);
        box-shadow: 4px 4px 6px 0 #FF56F6;
    }
`
const MediumImage = styled.img`
    width: 431px;
    margin-bottom: 100px;
    margin-top: 50px;
`
const CalContent2 = styled.div`
    width: 100%;
    max-width: 400px;
    // border-bottom: 2px solid #9F6FED;
    flex-direction: row;
    justify-content:space-between;
    display: flex;
    align-items:center;
    background-color: #8F4AFF;
    margin-top: 10px;
    border-radius: 100%;
    font-weight: bold;
    
`
const Calculator = styled.div`
    // width: 100px;
    // margin: 0 auto;
    flex-direction: row;
    justify-content:space-between;
    align-items:center;
    display: flex;
    margin-right: -27px;
`
const CalContent1 = styled.div`
    width: 100%;
    margin: 0 auto;
    flex-direction: row;
    justify-content:space-between;
    display: flex;
    align-items:center;
`
const ButtonItem = styled.p`
    color: white;
    font-size: 18px;
    align-self: center;
    font-family: Orbitron-Regular;
    font-weight: 700;
    margin: 0 30px;
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const RightButtonItem = styled.p`
    color: white;
    font-size: 16px;
    align-self: center;
    font-family: Orbitron-Regular;
    font-weight: 700;
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const RightButtonItemArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    width: 255px;
    height: 71px;
    background-image: url(/images/mintButtonImage.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-right: 50px;
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const WatchButtonItemArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    width: 255px;
    height: 52px;
    background-image: url(/images/watchButtonImage.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const LogoTitle5 = styled.a`
    color: white;
    font-size: 14px;
    text-align:center;
    display: inline-block;
    &:hover {
        cursor: pointer;
    }
`
const LogoTitle = styled.p`
    color: white;
    font-size: 24px;
    align-self: center;
    font-family:BaiJamjuree-Regular; 
    font-weight: 700;
    &:hover {
        cursor: pointer;
        text-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const LogoTitle1 = styled.p`
    color: #000;
    font-size: 18px;
    text-align:center;
    display: inline-block;
    background-color: #C4C4C4;
    width: 110px;
    height: 30px;
    border-radius: 15px;
    padding-top: 3px;
    font-family: Orbitron-Regular;
`
const LogoTitle4 = styled.p`
    color: #FFFFFF;
    font-size: 25px;
    text-align:center;
    display: inline-block;
    font-weight:700;
    padding: 0px;
    margin: 0px;
    &:hover {
        cursor: pointer;
    }
`
const CountDownTxt = styled.p`
    color: #FFFFFF;
    font-size: 48px;
    text-align:center;
    display: inline-block;
    font-weight:700;
    margin-bottom: 50px;
    margin-top: 0;
    width: 100%;
    @media (max-width: 660px) {
        font-size: 36px;
    }
`
const LogoTitle3 = styled.p`
    color: white;
    font-size: 22px;
    height:20px;
    width:50px;
    border-radius:25px;
    align-items:center;
    justify-content:center;
    text-align:center;
    display: inline-block;
    &:hover {
        cursor: pointer;
    }
`
const StackImage = styled.img`
    width: 100%;
    max-width: 629px;
`
const StackInfoArea = styled.div`
    width: 100%;
    background-color: rgb(${colors.subMain});
    padding: 4.5em 0 6em 0;
`
const AmountInput = styled.input`
    outline: none;
    margin: 0 1em;
    background-color: #C4C4C4;
    width: 110px;
    height: 30px;
    border-radius: 15px;
    border: 0;
    padding-left: 10px;
    text-align:center;
    margin: 10px;
`
const ImageContainer = styled.div`
    width: 80%;
    flex-direction: column;
    display: flex;
    align-items:center;
    margin: 0 auto;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 2em;
    }
`
const AprrovingArea = styled.div`
    width: 70%;
    flex-direction: column;
    display: flex;
    margin: 0 auto;
    align-items:center;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 2em;
    }
`
const BottomButtonArea = styled.div`
    width: 100%;
    flex-direction: row;
    display: flex;
    margin: 0 auto;
    align-items:center;
    justify-content: space-around;
    margin-top: 70px;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 2em;
    }
`
const BottomButton = styled.div`
    width: 100px;
    height: 41px;
    flex-direction: column;
    display: flex;
    align-items:center;
    background: linear-gradient(120deg, rgba(60, 121, 212,1), rgba(255, 166, 250,0.47) );
    border-radius: 30px;
    border-width: 2px;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 2em;
    }
    &:hover {
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const BottomButtonText = styled.div`
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, rgba(217, 0, 249,1), rgba(66,0,138,0.47) );
    color: white;
    font-size: 14px;
    font-family: Orbitron-Regular;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content: center;
    border-radius: 28px;
`
const ProcessingArea = styled.div`
    width: 100%;
    flex-direction: column;
    display: flex;
    margin: 0 auto;
    align-items: flex-start;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 2em;
    }
`
const ProcessingBar = styled.div`
    width: 100%;
    height: 1px;
    background-color: #FF56F6;
    flex-direction: column;
    display: flex;
    align-items:center;
    margin: 0 auto;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 2em;
    }
`
const ProcessingLine = styled.div`
    width: 50%;
    height: 3px;
    background-color: #FF56F6;
    flex-direction: column;
    display: flex;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 2em;
    }
`
const ProcessingLine1 = styled.div`
    width: 100%;
    height: 3px;
    background-color: #FF56F6;
    flex-direction: column;
    display: flex;
    @media screen and (max-width: 959px) {
        width: 100%;
        justify-content: center;
        padding-bottom: 2em;
    }
`
const ConnectButton = styled.button`
    border: none;
    background: white;
    color: black;
    font-size:  30px;
    border-radius: 10px;
    font-weight: bold;
    box-shadow: none;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 50px;
    height: 50px;
    &:hover {
        box-shadow: 0 4px 8px 0 rgba(${colors.border});
    }
`
const CalArea = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    text-align: center;
    max-width: 450px;
    width: 100%;
    padding: 40px;
    margin-top: 0em;
    border-radius: 10px;
    @media (max-width: 660px) {
        padding: 20px;
    }
`
const CalContent = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #9F6FED;
    flex-direction: row;
    align-items:center;
    padding: 20px 0;
`
const BuyButton = styled.button`
    color: white;
    font-size: 14px;
    border-radius: 22.5px;
    width: 50%;
    padding: 15px;
    font-weight: 700;
    margin-top: 50px;
    margin-bottom: 100px;
    font-family: Orbitron-Regular;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 200px;
    height: 45px;
    background: linear-gradient(0deg, rgba(255, 0, 214,1), rgba(255,0,175,0.47) );
    &:hover {
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const TitleArea1 = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1233px;
    width: 100%;
    justify-content:center;
    align-items:center;
    padding: 40px 0;
    background-color: #33002B;
    margin-bottom: 30px;
    margin-top: -20px;
    border-top: 1px solid #FF56F6;
    border-bottom: 1px solid #FF56F6;
    media screen and (max-width: 560px) {
        padding: 20px 0;
    }
`
const LoadmapTitle = styled.p`
    font-size: 96px;
    color: white;
    font-family:fenwick-outline;;    
    margin: 0;
    text-align:center;
    @media screen and (max-width: 560px) {
        font-size: 44px;
    }
`

const theme = createTheme({
    palette: {
        primary: {
            main: '#FFFFFF',
        },
    }
});

interface Props {
    balance: number,
    totalPrice: number,
    onStakAmountChangeButtonClick: (dir: number) => void,
    onStakReleaseChangeButtonClick: (dir: number) => void,
    onMaxButtonClick: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onStakeAmountHandle: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onStakeReleaseHandle: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onCallMint: () => void,
    add_chain_with_web3: () => void,
    amount: number,
    stakeRelease: number,
    stakeAmount: number,
    maxAmount: number,
    itemPrice: number,
    mintLoading: number,
    connect: () => void,
    connected: boolean,
    connectFlag: boolean,
}

let totalprice = 0;

const RewardAreaContainer: React.FC<Props> = ({
    balance,
    onStakAmountChangeButtonClick,
    onStakReleaseChangeButtonClick,
    onMaxButtonClick,
    onCallMint,
    amount,
    stakeRelease,
    stakeAmount,
    totalPrice,
    maxAmount,
    itemPrice,
    connect,
    connected,
    connectFlag,
    add_chain_with_web3,
    mintLoading,
    onStakeReleaseHandle,
    onStakeAmountHandle
}) => {

    let history = useHistory()

    const [daysInfo, setDaysInfo] = useState(0);
    const [hoursInfo, setHoursInfo] = useState(0);
    const [minutesInfo, setMinutesInfo] = useState(0);
    const [secondsInfo, setSecondsInfo] = useState(0);
    const [expiredFlag, setExpiredFlag] = useState(false);
    const [loadingNum, setLoadingNum] = useState(0);

    useEffect(() => {
        console.log("mintLoading", mintLoading)
        setLoadingNum(mintLoading)
    }, [mintLoading])


    useEffect(() => {
        AOS.init();
        if (countDownData.countdown) {
            var countDownDate = countDownData.countDownDate
            var x = setInterval(function () {

                // Get today's date and time
                var now = new Date().getTime();

                // Find the distance between now and the count down date
                var distance = countDownDate - now;

                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                setDaysInfo(days);
                setHoursInfo(hours);
                setMinutesInfo(minutes);
                setSecondsInfo(seconds);

                // Display the result in the element with id="demo"

                // If the count down is finished, write some text
                if (distance < 0) {
                    clearInterval(x);
                    setExpiredFlag(true);
                }
            }, 1000);
        }
    }, []);

    const navigateTo = (targetPath: string) => {
        history.push(`/${targetPath}`)
    }

    // if (countDownData.countdown) {
    //     return (
    //         <BannerContainer>
    //             {
    //                 expiredFlag ?
    //                     <CountDownTxt>EXPIRED</CountDownTxt> :
    //                     <Container>
    //                         <CountDownTxt><span style={{ color: "#fc5eff" }}>SINS</span> Drop In:</CountDownTxt>
    //                         <CountDownTxt>{daysInfo} <span style={{ fontSize: 25 }}>Days</span> {hoursInfo} <span style={{ fontSize: 25 }}>Hours</span> {minutesInfo} <span style={{ fontSize: 25 }}>Mins</span> {secondsInfo} <span style={{ fontSize: 25 }}>Secs</span></CountDownTxt>
    //                     </Container>
    //             }
    //         </BannerContainer>
    //     )
    // }


    return (
        <Container1 id="reward">
            <BannerContainer>
                <MainContent>
                    <TitleArea1>
                        <LoadmapTitle>MINTING REWARDS</LoadmapTitle>
                    </TitleArea1>
                    <TopImage src={rewardImage} alt="" data-aos="fade-up" />
                    <Description data-aos="fade-up">
                        25 MINTERS — will win a 3D avatar upgrade package and be automatically entered into Phase 2. They will receive a base avatar and NFT upgrade package to match their character. These can be used in the SINS video game open world and competition Arena (see more below about the Arena)<br /><br />
                        25 MINTERS — will have their characters chosen as the first 25 characters of the SINS Unreal-powered cinematic narrative. Each month users can watch their favorite SINS characters in a motion capture based, Unreal Engine created NFT streaming series. Each month, community votes on plot progression prior to the next script being written. The more avatars and content you own the more you influence the story. These minters get a first look deal at these 3D character upgrades if they so wish to own them. If they decline after the grace period, the 3D version is released into the general minting.<br /><br />
                        10 MINTERS — will win a luxury game-ready 3D supercar. 10 are available. 1st come first serve. These will be chosen randomly from the top 100 rarest NFTs minted.<br />
                        <span style={{ fontSize: 16, fontFamily: 'BaiJamjuree-Light' }}>- contingent on 25% sales</span><br /><br />
                        1st 100 MINTERS receive 50% discount on 1st 3D avatar upgrade.<span style={{ fontSize: 16, fontFamily: 'BaiJamjuree-Light' }}> - contingent on 25% sales</span><br /><br />
                        <span style={{ fontWeight: 'bold' }}>1000 Sold</span><br />
                        Custom Sins art NFT 1/1 &<br />
                        400$ Giveaway to # 1000 Holder<br /><br />
                        <span style={{ fontWeight: 'bold' }}>2000 Sold</span><br />
                        Holders NFT Giveaway (10 Sins prize pool)<br /><br />
                        <span style={{ fontWeight: 'bold' }}>3000 Sold</span><br />
                        Holders BNB Giveaway (3000$ prize pool)<br /><br />
                        <span style={{ fontWeight: 'bold' }}>4000 Sold</span><br />
                        Top 10 Holders BNB Giveaway (10000$ prize pool)<br />
                        Custom Sins art NFT 1/1 Giveaway to # .4000 Holder<br /><br />
                        <span style={{ fontWeight: 'bold' }}>5000 Sold</span><br />
                        Holders NFT Giveaway (10 Sins prize pool)<br /><br />
                        <span style={{ fontWeight: 'bold' }}>6000 Sold</span><br />
                        Holders BNB Giveaway (15000$ prize pool to 40 Holders)<br /><br />
                        <span style={{ fontWeight: 'bold' }}>7000 Sold</span><br />
                        Custom Sins art NFT 1/1 Giveaway to # .7000 Holder<br />
                        Holders Giveaway (3x 3D Upgrade Package)<br /><br />
                    </Description>
                    <TitleArea1 id="staking" style={{ marginTop: 50 }}>
                        <LoadmapTitle>STAKING = 10%</LoadmapTitle>
                    </TitleArea1>
                    <Description data-aos="fade-up">
                        Staking your SMBR tokens means you receive 10% of NFT sales from SINS of Shadow. The 10% is taken from the overall inital sales and then distributed to the stakers based on the value of their staking. The more you stake the more rewards you get. To participate users must stake their tokens for 6 months. Over that 6 month period their tokens and their rewards will be vested out. <br /><br />
                        By staking you’re helping stabilize the economics of the project as well as receiving many perks in the process. Thank you for staking! <br /><br />
                    </Description>
                </MainContent>
                <BuyButton onClick={() => navigateTo("stake")} >STAKE SMBR</BuyButton>
                {/* <Container>
                    <Grid container spacing={1} className={classes.topArea}>
                        <Grid item md={12} xs={12} className={classes.calcArea}>
                            <CalArea data-aos="fade-up">
                                <CalContent>
                                    <LogoTitle>SMBR REWARDS:</LogoTitle>
                                    <LogoTitle1>1000</LogoTitle1>
                                </CalContent>
                                <CalContent>
                                    <LogoTitle>SMBR STAKED:</LogoTitle>
                                    <LogoTitle1>7777</LogoTitle1>
                                </CalContent>
                                <CalContent>
                                    <LogoTitle>TIME UNTIL RELEASE:</LogoTitle>
                                    <LogoTitle1>00:00:00</LogoTitle1>
                                </CalContent>
                                <CalContent>
                                    <LogoTitle>STAKE AMOUNT:</LogoTitle>
                                    <Calculator>
                                        <LogoTitle4 onClick={() => { onStakAmountChangeButtonClick(-1) }}>-</LogoTitle4>
                                        <AmountInput type="number" value={stakeAmount} onChange={onStakeAmountHandle} />
                                        <LogoTitle4 onClick={() => { onStakAmountChangeButtonClick(1) }}>+</LogoTitle4>
                                    </Calculator>
                                </CalContent>
                                <CalContent>
                                    <LogoTitle>STAKE RELEASE:</LogoTitle>
                                    <Calculator>
                                        <LogoTitle4 onClick={() => { onStakReleaseChangeButtonClick(-1) }}>-</LogoTitle4>
                                        <AmountInput type="number" value={stakeRelease} onChange={onStakeReleaseHandle} />
                                        <LogoTitle4 onClick={() => { onStakReleaseChangeButtonClick(1) }}>+</LogoTitle4>
                                    </Calculator>
                                </CalContent>
                                <BottomButtonArea>
                                    <BottomButton>
                                        <BottomButtonText>STAKE</BottomButtonText>
                                    </BottomButton>
                                    <BottomButton>
                                        <BottomButtonText>RELEASE</BottomButtonText>
                                    </BottomButton>
                                </BottomButtonArea>
                            </CalArea>
                        </Grid>
                    </Grid>
                </Container> */}
            </BannerContainer>
            {/* <AprrovingArea data-aos="fade-up"> */}
            {/* {
                    loadingNum === 0 ? null :
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Description style={{ textAlign: 'center' }}>
                                {loadingNum === 1 ? "Approving transaction..." : "Item minted successfully."}
                            </Description>
                            <ProcessingArea>
                                {loadingNum === 1 ? <ProcessingLine /> : <ProcessingLine1 />}
                                <ProcessingBar />
                            </ProcessingArea>
                        </div>
                } */}
            {/* </AprrovingArea> */}
        </Container1>
    )
}

export default RewardAreaContainer
