import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../styles'
import logo from '../../assets/images/logo-large.png'
import sombraImage from '../../assets/images/sombraImage.png'

interface Props {

}

const FooterContainer = styled.div`
    width: 100%;
    background: #20083B;
    display: flex;
    flex-direction: row;
    padding: 0px 10%;
    @media (max-width: 970px) {
        flex-direction: column;
        padding: 0px 10px;
    }
`
const FooterLogoArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    padding: 50px 0;
    padding-left: 70px;
    @media (max-width: 970px) {
        width: 100%;
        padding: 0px 20px;
        padding-bottom: 50px;
    }
`
const LogoArea = styled.div`
    display: flex;
    flex-direction: row;
`
const LogoImage = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 1em;
    @media (max-width: 500px) {
        width: 80px;
        height: 80px;
        margin-bottom: 1em;
    }
`
const LogoTitle = styled.p`
    color: white;
    font-size: 52px;
    font-family: "NexaTextDemo-Bold";
    @media (max-width: 500px) {
        font-size: 28px;
    }
`
const MenuTitle = styled.p`
    color: #9EB8E9;
    font-size: 16px;
    font-weight: 400;
    font-family: Poppins-Regular;
    margin: 0.5em 0em;
    &:hover {
        cursor: pointer;
        text-shadow: 1px 1px 2px white, 0 0 1em white, 0 0 0.2em darkblue
    }
    @media (max-width: 970px) {
        font-size: 12px;
        margin: 0.5em 0em;
    }
`
const MenuTitle2 = styled.p`
    color: #9EB8E9;
    font-size: 16px;
    font-weight: 400;
    font-family: Poppins-Regular;
    margin: 0.5em 0em;
    @media (max-width: 970px) {
        font-size: 12px;
        margin: 0.5em 0em;
    }
`
const MenuTitle1 = styled.p`
    color: white;
    font-size: 16px;
    font-weight: 400;
    font-family: TitilliumWeb-Regular;
    margin: 0.5em 0em;
    @media (max-width: 970px) {
        margin: 0.5em 0em;
    }
`
const LeftArea = styled.div` 
    display: flex;
    flex-direction: row;
    width: 60%;
    align-items:center;
    justify-content: space-around;
    border-right: 0.5px solid #6F6679;
    padding: 50px 0;
    @media (max-width: 970px) {
        width: 100%;
        border-right: 0px solid #6F6679;
    }
`
const ItemListArea = styled.div` 
    display: flex;
    flex-direction: column;
`
const BottomButton = styled.div`
    width: 207px;
    height: 61px;
    flex-direction: column;
    display: flex;
    align-items:center;
    background: linear-gradient(120deg, rgba(60, 121, 212,1), rgba(255, 166, 250,0.47) );
    border-width: 2px;
    display: block;
    @media screen and (max-width: 970px) {
        display: none;
        width: 100%;
        justify-content: center;
        padding-bottom: 2em;
    }
    &:hover {
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const BottomButton1 = styled.div`
    width: 207px;
    height: 61px;
    flex-direction: column;
    display: flex;
    align-items:center;
    background: linear-gradient(120deg, rgba(60, 121, 212,1), rgba(255, 166, 250,0.47) );
    border-width: 2px;
    display: none;
    @media screen and (max-width: 970px) {
        display: block;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 5em;
    }
    &:hover {
        cursor: pointer;
        box-shadow: 0 4px 8px 0 rgba(${colors.main}), 0 6px 10px 0 rgba(${colors.main});
    }
`
const BottomButtonText = styled.div`
    width: 203px;
    height: 57px;
    margin-top: 2px;
    margin-left: 2px;
    background: linear-gradient(120deg, rgba(217, 0, 249,1), rgba(66,0,138,0.47) );
    color: white;
    font-size: 18px;
    font-family: Orbitron-Regular;
    font-weight: 700;
    text-align:center;
    display:flex;
    align-items:center;
    justify-content: center;
`
const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '3em 4em',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}));

const Footer = (props: Props) => {
    let history = useHistory()
    const classes = useStyles();
    const navigateTo = (targetPath: string) => {
        history.push(`/${targetPath}`)
    }
    return (
        <FooterContainer>
            <LeftArea>
                <ItemListArea>
                    <MenuTitle1>SOCIAL</MenuTitle1>
                    <MenuTitle onClick={() => {
                        window.open("https://t.me/sombranetwork", '_blank')
                    }}>Telegram</MenuTitle>
                    <MenuTitle onClick={() => {
                        window.open("https://bscscan.com/token/0x8ad8e9b85787ddd0d31b32ecf655e93bfc0747ef", '_blank')
                    }}>Bscscan</MenuTitle>
                    <MenuTitle onClick={() => {
                        window.open("https://discord.gg/gfvwXkzgAT", '_blank')
                    }}>Discord</MenuTitle>
                    <MenuTitle onClick={() => {
                        window.open("https://sombranetwork.medium.com", '_blank')
                    }}>Medium</MenuTitle>
                    <MenuTitle onClick={() => {
                        window.open("https://twitter.com/SombraNetwork", '_blank')
                    }}>Twitter</MenuTitle>
                    <MenuTitle onClick={() => {
                        window.open("https://www.linkedin.com/company/sombranetwork", '_blank')
                    }}>Linkedin</MenuTitle>
                </ItemListArea>
                <ItemListArea>
                    <MenuTitle1>RESOURCES</MenuTitle1>
                    <MenuTitle onClick={() => {
                        window.open("https://spark.adobe.com/page/AVHVkM7HXH9SM/", '_blank')
                    }}>Dark Paper</MenuTitle>
                    <MenuTitle onClick={() => {
                        window.open("https://www.sombraswap.com", '_blank')
                    }}>Buy SMBR</MenuTitle>
                    <MenuTitle onClick={() => {
                        window.open("https://www.sombra.app/", '_blank')
                    }}>Market</MenuTitle>
                    <MenuTitle onClick={() => navigateTo("law")}>Sombra Law</MenuTitle>
                    <MenuTitle onClick={() => navigateTo("terms")}>Terms &Conditions</MenuTitle>
                    <MenuTitle onClick={() => navigateTo("privacy")}>Privacy Policy</MenuTitle>
                </ItemListArea>
                <BottomButton onClick={() => {
                    window.open("https://www.sombraswap.com", '_blank')
                }}>
                    <BottomButtonText>SMBR</BottomButtonText>
                </BottomButton>
            </LeftArea>
            <FooterLogoArea>
                <MenuTitle1>READ MORE</MenuTitle1>
                <LogoArea>
                    <LogoImage src={sombraImage} />
                    <MenuTitle1 style={{ color: '#DC00FC' }}>MEDIUM</MenuTitle1>
                </LogoArea>
                <MenuTitle2>Visit our Medium for more info about SINS of SHADOW and our NFT avatar ecosystem.</MenuTitle2>
            </FooterLogoArea>
            <BottomButton1>
                <BottomButtonText>SMBR</BottomButtonText>
            </BottomButton1>
        </FooterContainer >
    )
}

export default Footer
